



















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IArea, IVenue } from '@einfachgast/shared';
import { IQrCode } from '@/interfaces/i-qr-code';
import { QrCode } from '@/models/qr-code';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import VenuesAreaItem from '@/components/venues/venues-area-item.vue';
import { createQrCodeForArea, getRandomId, createQrCodeSvg } from '@/helpers/qr-code-creation-helper';
import { Area } from '@/models/area';
import firebase from 'firebase/app';
import { FormFunctions } from '@/mixins/form-functions';
import { QrCodePrintTypes } from '@/models/qr-code-print-types';
import VenueQrCodePrintLangSelect from '@/components/venues/venue-qr-code-print-lang-select.vue';
import { QrCodePrintService } from '@/models/qr-code-print-service';

@Component({
  name: 'VenuesArea',
  components: {
    VenuesAreaItem
  }
})
export default class VenuesArea extends Vue {
  isSelectQrCodeLanguageActive = false;
  loading = false;
  qrCodePrintService: QrCodePrintService = null;
  adminArea: IArea = { id: 'admin-qr-code', name: 'Admin-QR-Code', isDeactivated: false, limit: 1, label: 'Admin Label' };

  @Prop()
  cssClasses: string;

  @Prop()
  venue: IVenue;

  get qrCodePrintTypes () {
    return QrCodePrintTypes;
  }

  get qrCodes () {
    return this.qrCodePrintService?.qrCodes || [];
  }

  get qrCodeLoading () {
    return this.qrCodePrintService?.loading;
  }

  get adminQrCode () {
    return this.qrCodePrintService?.qrCodes.find(x => x.isAdminCode === true);
  }

  get hasDesignPackage () {
    return this.$stripe.packageScope?.hasDesignPackage;
  }

  get addAreaTooltipLabel () {
    if (this.$stripe.packageScope?.isTrial) {
      return 'In der Testphase können keine weiteren Bereiche mehr angelegt werden.';
    }
    return 'Mit Ihrem aktuellen Plan können keine weiteren Bereiche mehr angelegt werden.';
  }

  /* As the function.name already says, it is checked whether you can add another area.
    packageScope.maxAreaCount: number = max create of areas; -1 = infinite; 0 = none
    packageScope.maxAreaTrialCount: number = max create areas in TrialSubscription
    packageScope.isTrial: boolean = if the subscription is in trial
  */
  get canAddNewArea () {
    // When maxAreaCount === 0 => you can't add a Area
    // OR
    // If it is a TrialSubscription, a maximum of 4 (configurable in product in stripe) areas can be created.
    // Because maxAreaCount is usually "infinite" in the TrialSubscription.
    if (this.$stripe.packageScope?.maxAreaCount === 0 || (this.$stripe.packageScope?.isTrial
      && this.venue.areas.length >= this.$stripe.packageScope.maxAreaTrialCount)) {
      return false;
    }
    // When maxAreaCount === -1 you can "infinite" add areas
    if (this.$stripe.packageScope?.maxAreaCount === -1) {
      return true;
    }
    // else => check against maxAreaCount
    return this.venue.areas.length < this.$stripe.packageScope?.maxAreaCount;
  }

  async mounted () {
    this.loading = true;
    const qrCodes: IQrCode[] = [];
    if (this.venue.id) {
      const querySnapshot = await fb.db.collection('qrCodes')
        .where('venueId', '==', this.venue.id)
        .get();
      querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
        const qrCodeData = doc.data();
        qrCodeData.id = doc.id;
        qrCodes.push(new QrCode(qrCodeData));
      });
    }
    this.qrCodePrintService = new QrCodePrintService(this.venue, this.$stripe.packageScope, qrCodes, this);
    this.loading = false;
  }

  addArea () {
    // get randomId for AreaId
    let randomId = getRandomId();
    while (randomId === this.venue.areas.find(x => x.id === randomId)?.id) {
      randomId = getRandomId();
    }
    this.venue.areas.push(new Area({
      id: randomId,
      name: '',
      isDeactivated: false,
      label: '',
      limit: null
    }));
  }

  areaSaved (area: IArea) {
    this.$emit('areaSaved', area);
  }

  deleteArea (areaId: string) {
    this.venue.areas.splice(this.venue.areas.findIndex(area => area.id === areaId), 1);
  }

  async createSvgQrCode (area: IArea) {
    let foundQrCode = this.qrCodes.find(x => x.areaId === area.id && x.venueId === this.venue.id);
    // when no qrCode exists => create one
    if (!foundQrCode) {
      const newQrCode = await createQrCodeForArea(this.venue, area, QrCodePrintTypes.Single);
      if (!newQrCode) {
        return;
      }
      this.qrCodePrintService.qrCodes.push(newQrCode);
      foundQrCode = newQrCode;
    }
    createQrCodeSvg(foundQrCode, this.venue, area);
  }

  preSelectQrCodePrintAttributes (printType: QrCodePrintTypes, areas?: IArea[]) {
    const ComponentClass = Vue.extend(VenueQrCodePrintLangSelect);
    const instance = new ComponentClass();
    instance.$on('printQrCode', (selectedLang: string) => {
      (this.$refs['qr-code-lang-select-container'] as HTMLElement).removeChild(instance.$el);
      instance.$destroy();
      if (printType === QrCodePrintTypes.Admin) {
        this.printAdminQrCode(selectedLang);
      } else if (printType === QrCodePrintTypes.All) {
        this.printAllQrCodes(selectedLang);
      } else {
        this.qrCodePrintService.prepareQrCodeHtmlAndPrint(areas, printType, selectedLang);
      }
    });
    instance.$on('close', () => {
      (this.$refs['qr-code-lang-select-container'] as HTMLElement).removeChild(instance.$el);
      instance.$destroy();
    });
    instance.$mount();
    (this.$refs['qr-code-lang-select-container'] as HTMLElement).appendChild(instance.$el);
  }

  async printAllQrCodes (lang: string) {
    // on print ALL qrCodes u have to save all areas
    const saved = await this.saveAllAreas();
    if (saved) {
      await this.qrCodePrintService.prepareQrCodeHtmlAndPrint(this.venue.areas.filter(x => x.isDeactivated === false), QrCodePrintTypes.All, lang);
    }
  }

  async printAdminQrCode (lang: string) {
    if (this.adminArea) {
      const saved = await this.saveAllAreas();
      if (saved) {
        await this.qrCodePrintService.prepareQrCodeHtmlAndPrint([this.adminArea], QrCodePrintTypes.Admin, lang);
      }
    }
  }

  async saveAllAreas () {
    let areaItemHasNoError = true;
    for (const areaItemRef of (this.$refs.venueAreaItem as Vue[])) {
      if (!await (areaItemRef as FormFunctions).validate((areaItemRef as VenuesAreaItem).area)) {
        areaItemHasNoError = false;
      }
    }
    try {
      if (areaItemHasNoError) {
        this.loading = true;
        // on click qrCodePrint save the area (only when changed)
        await this.$venues.updateVenue(this.venue);
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Die Standortbereiche wurden gespeichert.',
          position: 'is-top',
          type: 'is-success'
        });
        this.loading = false;
        return true;
      }
    } catch (e) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger'
      });
    }
    return false;
  }
}
