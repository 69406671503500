
















































































import { Component, Prop } from 'vue-property-decorator';
import { IVenue, CheckInModes } from '@einfachgast/shared';
import VenuesOptionsModusItem from '@/components/venues/venues-options/venues-options-modus-item.vue';
import VenuesOptionsFunctionItem from '@/components/venues/venues-options/venues-options-function-item.vue';
import VenuesOptionsCheckinCheckoutContent from '@/components/venues/venues-options/venues-options-checkin-checkout-content.vue';
import VenuesOptionsStandardContent from '@/components/venues/venues-options/venues-options-standard-content.vue';
import VenuesUserVisitContent from '@/components/venues/venues-options/venues-user-visit-content.vue';
import VenuesCovidOptions from '@/components/venues/venues-options/venues-covid-options.vue';
import { VenueOptionsVsitiorEnum } from '@/models/venues/venue-options-visitor-enum';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';

@Component({
  name: 'VenuesOptions',
  components: {
    VenuesOptionsModusItem,
    VenuesOptionsCheckinCheckoutContent,
    VenuesOptionsStandardContent,
    VenuesOptionsFunctionItem,
    VenuesUserVisitContent,
    VenuesCovidOptions
  }
})
export default class VenuesOptions extends mixins(FormFunctions) {
  @Prop()
  venue: IVenue;

  get venueOptionsVsitiorEnum () {
    return VenueOptionsVsitiorEnum;
  }

  get DefaultMode () {
    return CheckInModes.Standard;
  }

  get CheckInCheckOutMode () {
    return CheckInModes.CheckInCheckOut;
  }

  get IsDefaultMode () {
    return this.venue.checkinMode === this.DefaultMode;
  }

  get IsCheckInOutMode () {
    return this.venue.checkinMode === this.CheckInCheckOutMode;
  }

  changeIsUserVisit (isUserVisitString: string) {
    this.venue.isUserVisit = (isUserVisitString === VenueOptionsVsitiorEnum.visitorRegistration);
  }
}
