











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CountdownTimer'
})
export class CountdownTimer extends Vue {
  @Prop({ default: 0 })
  seconds: number;

  iv: number = null;
  countdown = 0;

  get Timeout () {
    return 1000;
  }

  get Visible () {
    return this.countdown > 0;
  }

  start () {
    this.countdown = this.seconds;
    this.iv = window.setInterval(() => {
      this.tick();
    }, this.Timeout);
  }

  stop () {
    clearInterval(this.iv);
  }

  reset () {
    this.countdown = this.seconds;
  }

  private tick () {
    this.countdown--;
    this.$emit('tick', this.countdown);

    if (this.countdown <= 0) {
      clearInterval(this.iv);
      this.$emit('finished', this.countdown);
    }
  }
}

export default CountdownTimer;
