




























































































































































































import { Component } from 'vue-property-decorator';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import { RegistrationData } from '@/models/registration-data';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import LoginHeaderToggle from '@/components/login/login-header-toggle.vue';
import { IRegistrationData } from '@/interfaces/i-registration-data';
import { VueEnvHelper } from '@/helpers/vue-envvar-helper';
import { RouteNames } from '@/router';
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { getAllCountriesByLang } from '@/helpers/country-helper';

@Component({
  name: 'Registration',
  components: {
    LoggedOutWrapper,
    LoginHeaderToggle
  }
})
export default class Registration extends mixins(FormFunctions) {
  registrationData: IRegistrationData = new RegistrationData()
  termsNotacceptedErrorMessage: string = null
  agreementErrorMessage: string = null
  termsAccepted = false
  agreementAccepted = false
  loading = false
  envHelper: VueEnvHelper = new VueEnvHelper('_RECAPTCHA');
  reCaptcha: ReCaptchaInstance = null;
  isActiveInfoMessage = false;

  async mounted () {
    await this.initReCaptcha();
  }

  get allCountries () {
    return getAllCountriesByLang();
  }

  async initReCaptcha () {
    this.reCaptcha = await load(this.envHelper.get('PUBLIC_KEY'));
  }

  async register () {
    if (!this.termsAccepted) {
      this.termsNotacceptedErrorMessage = 'Bitte akzeptieren Sie die Datenschutzbestimmungen';
    }
    if (!this.agreementAccepted) {
      this.agreementErrorMessage = 'Bitte akzeptieren Sie die Vereinbarung zur gemeinsamen Verantwortlichkeit';
    }

    if (!await this.validate(this.registrationData) || this.termsNotacceptedErrorMessage !== null
      || this.agreementErrorMessage !== null) {
      return;
    }

    this.loading = true;
    try {
      this.registrationData.reCaptchaToken = await this.reCaptcha.execute('register');
      await this.$auth.signup(this.registrationData).then(() => {
        this.$router.push({ name: RouteNames.Verification });
      });
    } catch (e) {
      let msg = e.message;
      if (msg === 'The email address is already in use by another account.') {
        // later it will be implemented with i18n
        msg = 'Für diese Email-Adresse ist bereits ein Konto vorhanden. <a href="/password-reset">Passwort vergessen?</a>';
      }
      this.$buefy.toast.open({
        duration: 6000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      });
    }
    this.loading = false;
  }

  login () {
    this.$router.push({ name: RouteNames.Login });
  }
}
