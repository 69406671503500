import { store } from '@/store';
import { IVenueModule } from '@/store/interfaces/i-venue-module';
import { VenueModule } from '@/store/modules/venue-module';
import _Vue from 'vue'; // <-- notice the changed import
import { getModule } from 'vuex-module-decorators';

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function VenuesPlugin (Vue: typeof _Vue): void {
  Vue.prototype.$venues = getModule(VenueModule, store);
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Vue {
    $venues: IVenueModule;
  }
}
