export class Duration {
  static msInDay = 86400000;
  static msInHour = 3600000;
  static msInMinute = 60000;
  static msInSecond = 1000;
  /**
   * Whole days (1 day and 13 hours = 1 day)
   */
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  /**
   * Half days are full days (1 day 13 hours = 2 days)
   */
  roundedDays: number;

  get hasElapsed () {
    return this.days === 0
    && this.hours === 0
    && this.minutes === 0
    && this.seconds === 0;
  }

  static fromMs (ms: number): Duration {
    let currentResult = Duration.resultWithRest(ms, this.msInDay);
    const duration = new Duration();
    duration.roundedDays = Math.round(ms / this.msInDay);
    duration.days = currentResult.result;
    currentResult = Duration.resultWithRest(currentResult.remaining, this.msInHour);
    duration.hours = currentResult.result;
    currentResult = Duration.resultWithRest(currentResult.remaining, this.msInMinute);
    duration.minutes = currentResult.result;
    currentResult = Duration.resultWithRest(currentResult.remaining, this.msInSecond);
    duration.seconds = currentResult.result;
    return duration;
  }

  static resultWithRest (total: number, factor: number): { result: number; remaining: number } {
    const curVal = Math.floor(total / factor);
    return {
      result: curVal,
      remaining: total % factor
    };
  }
}
