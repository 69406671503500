







































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { IArea, IVenue } from '@einfachgast/shared';

import firebase from 'firebase/app';
import LiveVisitorTimer from '@/components/live-visitors/live-visitor-timer.vue';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import LiveVisitorAreaItemCheckoutBtn from '@/components/live-visitors/live-visitor-area-item-checkout-btn.vue';

@Component({
  name: 'LiveVisitorAreaItem',
  components: {
    LiveVisitorTimer,
    LiveVisitorAreaItemCheckoutBtn
  }
})
export default class LiveVisitorAreaItem extends Vue {
  currentPage = 1;
  entriesPerPage = 20;
  checkoutAllLoading = false;
  searchStringByName = '';

  @Prop()
  venue: IVenue;

  @Prop()
  area: IArea;

  get filteredPendingVisits () {
    return this.pendingVisitsByArea
      .filter(x =>
        x.name
          .toLowerCase()
          .includes(this.searchStringByName.toLocaleLowerCase()));
  }

  get pendingVisitsByArea () {
    return this.venue.pendingVisits.filter(x => x.areaId === this.area.id);
  }

  get currentLimitAmount () {
    return this.pendingVisitsByArea.reduce((a, b) => a + b.visitorsCount, 0);
  }

  getVisistDurationInMin (visistStartDate: firebase.firestore.Timestamp) {
    const now = new Date();
    let diff = (now.getTime() - visistStartDate.toDate().getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  checkoutAllVisitors () {
    this.$buefy.dialog.confirm({
      message: 'Möchten Sie wirklich alle Gäste abmelden?',
      cancelText: 'abbrechen',
      onConfirm: async () => {
        if (!this.pendingVisitsByArea || this.pendingVisitsByArea.length === 0) {
          return;
        }
        try {
          this.checkoutAllLoading = true;
          // here checkout all visitors function
          const checkout = fb.functions.httpsCallable('checkoutAdmin');
          await checkout(this.pendingVisitsByArea.map(x => x.id));
          this.checkoutAllLoading = false;
        } catch {
          this.checkoutAllLoading = false;
          throw new Error(`Error on checkout all guests from ${this.venue.label}`);
        }
      }
    });
  }
}
