































import { Component, Vue } from 'vue-property-decorator';
import Chart from 'chart.js';
import { DashboardChartHelper } from '@/helpers/dashboard-chart-helper';
import 'chartjs-plugin-colorschemes';

@Component({
  name: 'DashboardPieChart'
})
export default class DashboardPieChart extends Vue {
  groupedBy: 'day' | 'month' = 'day';
  groups = [{
    value: 'day',
    label: 'Tag'
  }, {
    value: 'month',
    label: 'Monat'
  }];

  chart: Chart = null;
  dates: Date[] = [];
  filteredData: Array<{ label: string; count: number }> = [];
  dashboardChartHelper = new DashboardChartHelper();

  get venues () {
    return this.$auth.ownedVenues.filter(x => x.isDeactivated === false && x.isDeleted === false);
  }

  get datePickerType () {
    return (this.groupedBy === 'month') ? 'month' : null;
  }

  mounted () {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    this.dates.push(startDate, endDate);
    this.filterData();
    this.$watch(() => this.venues, () => {
      this.filterData(true);
    }, { deep: true });
  }

  changeGroupedBy () {
    if (this.groupedBy === 'month') {
      this.dates = [];
      const endDate = new Date();
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      this.dates.push(startDate, endDate);
    }
    this.filterData();
  }

  filterData (withoutAnimation?: boolean) {
    this.filteredData = [];
    for (const venue of this.venues) {
      const filtredVisitorsCount = this.dashboardChartHelper
        .getSumFilteredVisitorByStartAndEnd(venue, this.dates[0], this.dates[1], this.groupedBy);
      if (filtredVisitorsCount === 0) {
        continue;
      }
      this.filteredData.push({
        label: venue.label,
        count: filtredVisitorsCount
      });
    }
    if (withoutAnimation && this.chart) {
      this.chart.data.labels = this.filteredData.map(x => x.label);
      this.chart.data.datasets = [{
        label: 'Besucher',
        data: this.filteredData.map(x => x.count)
      }];
      this.chart.update({ duration: 0 });
    } else {
      this.initVisitorsPieChart();
    }
  }

  initVisitorsPieChart () {
    if (this.filteredData.length === 0) {
      return;
    }
    if (this.chart) {
      this.chart.destroy();
    }
    const pieChartEl = (document.getElementById('visitorPieChart') as HTMLCanvasElement);
    if (!pieChartEl) {
      return;
    }
    const ctx = pieChartEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: this.filteredData.map(x => x.label),
        datasets: [{
          label: 'Besucher',
          data: this.filteredData.map(x => x.count)
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          colorschemes: {
            scheme: 'brewer.Paired12'
          }
        }
      }
    });
  }
}
