

























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import ClickOutside from 'vue-click-outside';
import VenueInputTooltipWrapper from '@/components/venues/venue-input-tooltip-wrapper.vue';
import { checkFileSizeExceeded, maxFilesize } from '@/helpers/file-helper';
import ColorPickerFieldWrapper from '@/components/venues/venues-design/color-picker-field-wrapper.vue';
import TranslateableTextarea from '@/components/common/translateable-textarea.vue';

@Component({
  name: 'WebTab',
  components: {
    VenueInputTooltipWrapper,
    ColorPickerFieldWrapper,
    TranslateableTextarea
  },
  directives: {
    ClickOutside
  }
})
export default class WebTab extends Vue {
  dropFile: File = null;

  @Prop()
  venue: IVenue;

  get TooltipText () {
    return 'In Ihrem aktuellen Plan sind keine Design-Anpassungen enthalten. Vorhandene Einstellungen und Logos werden wiederhergestellt, sobald Sie in einen entsprechenden Plan wechseln.';
  }

  get hasDesignPackage () {
    return this.$stripe.packageScope.hasDesignPackage;
  }

  get logoFilename () {
    if (!this.dropFile) {
      return this.venue.logoUrl?.substring(this.venue.logoUrl?.lastIndexOf('/') + 1);
    }
    return this.dropFile.name;
  }

  onDropFile (droppedFile: File) {
    if (checkFileSizeExceeded(droppedFile)) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `Maximale Dateigröße von ${maxFilesize()}MB überschritten.`,
        position: 'is-top',
        type: 'is-danger'
      });
      this.dropFile = null;
    } else {
      this.$emit('onDropFile', droppedFile);
      this.venue.logoUrl = droppedFile.name;
    }
  }

  removeFile () {
    this.dropFile = null;
    this.venue.logoUrl = '';
    this.$emit('onDropFile', null);
  }
}
