import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Registration from '../views/Registration.vue';
import Venues from '../views/Venues.vue';
import Dashboard from '../views/Dashboard.vue';
import Verification from '../views/Verification.vue';
import Login from '../views/Login.vue';
import PasswordReset from '../views/PasswordReset.vue';
import { getModule } from 'vuex-module-decorators';
import { AuthModule } from '@/store/modules/auth';
import { store } from '@/store';
import MyData from '@/views/MyData.vue';
import RequestDataForm from '@/views/RequestDataForm.vue';
import ChangeMail from '@/views/ChangeMail.vue';
import Abonnement from '@/views/Abonnement.vue';
import LiveVisitors from '@/views/LiveVisitors.vue';
import VenueCreate from '@/components/venues/venues-create.vue';
import MyPlanMenuItemRenderer from '@/components/menu/renderers/my-plan.vue';
import NotFound from '@/components/not-found.vue';
import VenueEdit from '@/components/venues/venue-edit.vue';
import SuperAdminDashboard from '@/views/SuperAdminDashboard.vue';
import VisitorsDownload from '@/views/VisitorsDownload.vue';
import StripeRewrite from '@/views/StripeRewrite.vue';
import Settings from '@/views/Settings.vue';
import { Roles } from '@/models/venues/roles';

Vue.use(VueRouter);

enum RouteNames {
  Dashboard = 'Dashboard',
  MyData = 'MyData',
  Venues = 'Venues',
  Registration = 'Registration',
  Login = 'Login',
  PasswordReset = 'PasswordReset',
  Verification = 'Verification',
  ChangeMail = 'ChangeMail',
  RequestDataForm = 'RequestDataForm',
  Abonnement = 'Abonnement',
  NewVenue = 'newVenue',
  NotFound = 'notFound',
  EditVenue = 'editVenue',
  LiveView = 'liveView',
  SuperAdmin = 'superAdmin',
  VisitorDataDownload = 'visitorDataDownload',
  StripeCustomerPortal = 'stripeCustomerPortal',
  Settings = 'settings',
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: RouteNames.Dashboard,
    component: Dashboard,
    meta: {
      menuTitle: 'Dashboard',
      menuIcon: 'chart-line',
      menuSortOrder: 10
    }
  },
  {
    path: '/super-admin',
    name: RouteNames.SuperAdmin,
    component: SuperAdminDashboard,
    meta: {
      menuTitle: 'SuperAdmin',
      menuIcon: 'chart-line',
      menuSortOrder: 11,
      permission: Roles.isAdmin
    }
  },
  {
    path: '/stripe-customer-portal',
    name: RouteNames.StripeCustomerPortal,
    component: StripeRewrite,
    props: true,
    meta: {
      hideSidebar: true
    }
  },
  {
    path: '/live-view',
    name: RouteNames.LiveView,
    component: LiveVisitors,
    meta: {
      menuTitle: 'Live-Ansicht',
      menuIcon: 'account-group',
      menuSortOrder: 11
    }
  },
  {
    path: '/my-data',
    name: RouteNames.MyData,
    component: MyData,
    meta: {
      menuTitle: 'Meine Daten',
      menuIcon: 'account-outline',
      menuSortOrder: 40
    }
  },
  {
    path: '/settings',
    name: RouteNames.Settings,
    component: Settings,
    meta: {
      menuTitle: 'Einstellungen',
      menuIcon: 'cog',
      menuSortOrder: 41
    }
  },
  {
    path: '/abonnement',
    name: RouteNames.Abonnement,
    component: Abonnement,
    meta: {
      // menuTitle: 'Mein Plan',
      // menuIcon: 'calendar-check-outline'
      menuItemRenderer: MyPlanMenuItemRenderer,
      menuSortOrder: 30
    }
  },
  {
    path: '/venues',
    name: RouteNames.Venues,
    component: Venues,
    meta: {
      menuTitle: 'Standorte',
      menuIcon: 'map-marker-outline',
      menuSortOrder: 20
    }
  },
  {
    path: '/register',
    name: RouteNames.Registration,
    component: Registration,
    meta: {
      public: true,
      blueBg: true
    }
  },
  {
    path: '/login',
    name: RouteNames.Login,
    component: Login,
    props: true,
    meta: {
      public: true,
      blueBg: true
    }
  },
  {
    path: '/password-reset',
    name: RouteNames.PasswordReset,
    component: PasswordReset,
    props: true,
    meta: {
      public: true,
      blueBg: true
    }
  },
  {
    path: '/verify',
    name: RouteNames.Verification,
    component: Verification,
    meta: {
      hideSidebar: true,
      hideWizardHint: true,
      blueBg: true
    }
  },
  {
    path: '/changemail',
    name: RouteNames.ChangeMail,
    component: ChangeMail,
    meta: {
      hideSidebar: true,
      hideWizardHint: true,
      blueBg: true
    }
  },
  {
    path: '/request-data',
    name: RouteNames.RequestDataForm,
    component: RequestDataForm,
    meta: {
      menuTitle: 'Corona-Fall melden',
      menuIcon: 'police-badge-outline',
      menuSortOrder: 50,
      canShow: (vue: Vue) => {
        return vue.$auth.ownedVenues.find(x => !x.isUserVisit);
      }
    }
  },
  {
    path: '/visitors-data-download',
    name: RouteNames.VisitorDataDownload,
    component: VisitorsDownload,
    meta: {
      menuTitle: 'Besucherdaten',
      menuIcon: 'download',
      menuSortOrder: 51,
      canShow: (vue: Vue) => {
        return vue.$auth.ownedVenues.find(x => x.isUserVisit);
      }
    }
  },
  {
    path: '/newVenue',
    name: RouteNames.NewVenue,
    component: VenueCreate
  },
  {
    path: '/editVenue/:venueId',
    name: RouteNames.EditVenue,
    component: VenueEdit,
    props: true
  },
  {
    path: '*',
    name: RouteNames.NotFound,
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const auth = getModule(AuthModule, store);

router.beforeEach((to, from, next) => {
  if (to.meta.permission && !auth.permissions.has(to.meta.permission)) {
    next({ name: RouteNames.NotFound });
    return;
  }
  // Enforce login
  if (!to.meta.public && !auth.user) {
    next({ name: RouteNames.Login, params: { redirectUrl: to.fullPath } });
    return;
  }

  // users cant Do shit until email is verified
  if (auth.user && !auth.user.emailVerified && to.name !== RouteNames.Verification && to.name !== RouteNames.ChangeMail) {
    next({ name: RouteNames.Verification });
    return;
  }

  document.body.className = to.name?.toLowerCase();
  if (to.meta?.blueBg) {
    document.body.classList.add('blueBg');
  } else {
    document.body.classList.remove('blueBg');
  }
  next();
});

export { routes, RouteNames };
export default router;
