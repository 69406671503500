

























import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { routes } from '@/router/index';
import { RouteRecord } from 'vue-router';

@Component({
  name: 'Menu'
})
export default class Menu extends Vue {
  @Prop()
  plain!: boolean;

  get routes () {
    return routes
      .filter(x => x.meta && (!!x.meta.menuTitle || !!x.meta.menuItemRenderer))
      .filter(x => (x.meta.permission)
        ? this.$auth.permissions.has(x.meta.permission)
        : true
      )
      .filter(x => {
        if (x.meta.canShow) {
          return x.meta.canShow(this);
        }
        return true;
      })
      .sort((a, b) => a.meta?.menuSortOrder - b.meta?.menuSortOrder);
  }

  isActive (route: RouteRecord) {
    return this.$route.path === route.path;
  }

  logout () {
    this.$auth.logout();
  }
}
