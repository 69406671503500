export default {
  step: 'Schritt',
  myData: 'Meine Daten',
  registration: 'ANMELDUNG',
  howItWorks: 'SO GEHT\'S',
  scanQrCode: 'QR-Code scannen',
  enterContactDetails: 'Kontaktdaten eintragen',
  ready: 'Fertig',
  browser: 'Browser',
  encryptedDataTransmissionInfo: 'Verschlüsselte Datenübertragung &amp; Speicherung<br/>DSGVO-konform, Serverstandort in Deutschland',
  qrCodeIsNoIntendedForCustomers: 'Dieser QR-Code ist <strong>nicht</strong> für Kunden gedacht.<br/>Sofern ein Kunde kein Smartphone o.Ä. dabei hat, kann ein Mitarbeiter den Kunden mit Hilfe dieses QR-Codes eintragen.',
  deLang: 'Deutsch',
  enLang: 'English',
  esLang: 'Español',
  ruLang: 'русский',
  frLang: 'Français',
  trLang: 'Türk'
};
