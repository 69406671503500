import { ILoginData } from '@/interfaces/i-login-data';
import { IsEmail, MinLength } from 'class-validator';

export class LoginData implements ILoginData {
    @IsEmail({}, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein' })
    email = '';

    @MinLength(6, { message: 'Das Passwort muss mindestens 6 Zeichen lang sein' })
    password = '';
}
