// mixins.js
import Vue from 'vue';
import Component from 'vue-class-component';
import VueI18n from 'vue-i18n';
import { Prop } from 'vue-property-decorator';

// You can declare mixins as the same style as components.
@Component
export class LanguageScope extends Vue {
  i18n: VueI18n = null;

  @Prop()
  displayLanguage: string;

  created () {
    this.initLanguage();
  }

  initLanguage () {
    if (!this.i18n) {
      this.i18n = new VueI18n({
        locale: this.displayLanguage,
        messages: this.$i18n?.messages || this.i18n.messages
      });
    } else {
      this.i18n.locale = this.displayLanguage;
    }
  }

  mounted () {
    this.$watch(() => this.displayLanguage, this.initLanguage);
  }
}
