





























































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase from 'firebase/app';
import { IVenue } from '@einfachgast/shared';
import { IRequestFormData } from '@/interfaces';
import { RequestFormData } from '@/models/request-form-data';
import { Venue } from '@/models/venues/venue';

@Component({
  name: 'RequestDataForm'
})
export default class RequestDataForm extends mixins(FormFunctions) {
  loading = false;
  venueLoading = false;
  requestedDataLoading = false;
  requestFormData: IRequestFormData = null;
  venues: IVenue[] = [];
  requestedData: IRequestFormData[] = [];

  async created () {
    await this.fetchVenueData();
    this.initEmptyRequestFormData();
    await this.fetchRequestedData();
  }

  getVenueLabelById (venueId: string) {
    const catchedVenue = this.venues.find(x => x.id === venueId);
    if (!catchedVenue) {
      return 'Standort existiert nicht mehr';
    }
    return this.venues.find(x => x.id === venueId).label;
  }

  initEmptyRequestFormData () {
    this.requestFormData = new RequestFormData();
    this.requestFormData.status = 'in Bearbeitung';
    if (this.venues.length === 1) {
      this.requestFormData.venueId = this.venues[0].id;
      this.requestFormData.ownerId = this.venues[0].ownerId;
    }
  }

  setOwnerId (venueId: string) {
    const venue = this.venues.find(x => x.id === venueId);
    if (venue) {
      this.requestFormData.ownerId = venue.ownerId;
    }
  }

  async send () {
    if (!await this.validate(this.requestFormData)) {
      return;
    }
    this.loading = true;
    try {
      const createRequestData = fb.functions.httpsCallable('createRequestData');
      await createRequestData(this.requestFormData);
      // save here and send Email to us with the data of the form
      this.$buefy.toast.open({
        duration: 7000,
        message: 'Die gewünschten Daten wurden angefordert.<br/> Wir werden für Sie das Gesundheitsamt kontaktieren.<br/> Anhand des Status können Sie den aktuellen Stand nachverfolgen.',
        position: 'is-top',
        type: 'is-success'
      });
      await this.fetchRequestedData();
      // empty form
      this.initEmptyRequestFormData();
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger'
      });
    }
    this.loading = false;
  }

  async fetchVenueData () {
    this.venueLoading = true;
    const querySnapshot = await fb.db.collection('venues')
      .where('ownerId', '==', this.$auth.venueOwner.userId)
      .get();
    this.venues = [];
    querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
      const venueData = doc.data();
      venueData.id = doc.id;
      this.venues.push(new Venue(venueData));
    });
    this.venueLoading = false;
  }

  async fetchRequestedData () {
    this.requestedDataLoading = true;
    try {
      const querySnapshot = await fb.db.collection('requestData')
        .where('ownerId', '==', this.$auth.venueOwner.userId)
        .get();
      this.requestedData = [];
      querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
        const requestData = doc.data();
        requestData.id = doc.id;
        this.requestedData.push(new RequestFormData(requestData));
      });
    } catch (e) {
      this.requestedDataLoading = false;
    }
    this.requestedDataLoading = false;
  }
}
