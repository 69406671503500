import { IRequestFormData } from '@/interfaces';
import { MinLength } from 'class-validator';
import firebase from 'firebase/app';
import BaseModel from '@/models/base-model';

export class RequestFormData extends BaseModel<IRequestFormData> implements IRequestFormData {
  public id: string;
  public ownerId: string;
  public status: string;
  public createdAt: firebase.firestore.Timestamp;

  @MinLength(2, { message: 'Bitte geben Sie einen Standort an.' })
  public venueId: string;

  @MinLength(5, { message: 'Bitte geben Sie einen Ansprechpartner an.' })
  public healthDepartmentContact: string;

  @MinLength(8, { message: 'Bitte geben Sie eine Telefonnummer des Anrpechpartners an.' })
  public healthDepartmentPhone: string;

  @MinLength(5, { message: 'Bitte geben Sie das Gesundheitsamt an.' })
  public healthDepartmentName: string;

  public annotation?: string;
}
