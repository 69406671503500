












import Component from 'vue-class-component';
import Vue from 'vue';
import { RouteNames } from '@/router';

@Component({
  name: 'SetupHint'
})
export default class SetupHint extends Vue {
  // Original method, keeping it for historical reference
  // get mustShowSetup () {
  //   return (!(this.$route.meta?.hideWizardHint || this.$auth.isVenueOwnerDeleted || !this.$auth.isRegistrationComplete)
  //     && (!this.$auth.ownedVenues || this.$auth.ownedVenues.length <= 0));
  // }

  get IsVisible () {
    return !this.HideWizard // Don't show when the route has any objections
      && !this.OwnerDeleted // Don't show when the owner deleted his account
      && this.IsRegistrationComplete // Don't even think about showing this before the registration is complete
      && !this.HasAnyVenues // Show if no venues have been created yet
      && !this.IsNewVenueRoute // Don't display on the Create a new venue page
    ;
  }

  get HideWizard () {
    return this.$route.meta?.hideWizardHint;
  }

  get OwnerDeleted () {
    return this.$auth.isVenueOwnerDeleted;
  }

  get IsRegistrationComplete () {
    return this.$auth.isRegistrationComplete;
  }

  get HasAnyVenues () {
    return this.$auth.ownedVenues && this.$auth.ownedVenues.length > 0;
  }

  get IsNewVenueRoute () {
    return this.$route.name === RouteNames.NewVenue;
  }
}
