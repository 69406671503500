



























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import VenueDataStep from '@/components/venues/venue-steps/venue-data-step.vue';
import { IVenue, CheckInModes } from '@einfachgast/shared';
import { Venue } from '@/models/venues/venue';
import { classToClass } from 'class-transformer';
import { RouteNames } from '@/router';
import { IQrCode } from '@/interfaces/i-qr-code';
import VenueAdditionalFeaturesStep from '@/components/venues/venue-steps/venue-additional-features-step.vue';
import { CreateStep } from '@/models/venues/venue-steps';
import { FormFunctions } from '@/mixins/form-functions';
import VenuesAreaItem from './venues-area-item.vue';
import VenueAreasStep from '@/components/venues/venue-steps/venue-areas-step.vue';
import VenueQrCodeStep from '@/components/venues/venue-steps/venue-qr-code-step.vue';
import VenueWebFormStep from '@/components/venues/venue-steps/venue-web-form-step.vue';
import VenuesOptionsStep from '@/components/venues/venue-steps/venue-options-step.vue';
import { Route } from 'vue-router';
import VenuesOptions from './venues-options.vue';
import VenuesUserVisitContent from './venues-options/venues-user-visit-content.vue';

Component.registerHooks([
  'beforeRouteLeave'
]);

@Component({
  name: 'VenueCreate',
  components: {
    VenueDataStep,
    VenueAdditionalFeaturesStep,
    VenueAreasStep,
    VenueQrCodeStep,
    VenueWebFormStep,
    VenuesOptionsStep
  }
})
export default class VenueCreate extends Vue {
  loading = false;
  qrCode: IQrCode = null;
  droppedFile: File = null;
  droppedFileQrCode: File = null;
  droppedMenuFile: File = null;
  venue: IVenue = classToClass(new Venue());
  activeStep = CreateStep.Addressdata;
  isDirty = false;

  mounted () {
    if (this.$auth.ownedVenues.filter(venue => !venue.isDeleted && !venue.isDeactivated).length >= this.$stripe.packageScope.maxVenueCount) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Mit Ihrem aktuellen Plan können keine weiteren Standorte mehr angelegt werden.',
        position: 'is-top',
        type: 'is-danger'
      });
      this.$router.push({ name: RouteNames.Venues });
    }
    this.$watch(() => this.venue, () => {
      this.isDirty = true;
    }, { deep: true });
  }

  beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (this.isDirty) {
      this.$buefy.dialog.confirm({
        message: 'Es gibt ungespeicherte Änderungen auf dieser Seite.',
        cancelText: 'Seite verlassen',
        confirmText: 'Auf Seite bleiben',
        type: 'is-success',
        onConfirm: () => next(false),
        onCancel: () => next()
      });
    } else {
      next();
    }
  }

  onDropFile (droppedFile: File) {
    this.droppedFile = droppedFile;
  }

  onDropFileQrCode (droppedFileQrCode: File) {
    this.droppedFileQrCode = droppedFileQrCode;
  }

  onDropMenuFile (droppedMenuFile: File) {
    this.droppedMenuFile = droppedMenuFile;
  }

  get isAreaStep () {
    return this.activeStep === CreateStep.Areas;
  }

  get webDroppedFile () {
    return (this.droppedFile && this.droppedFile.name !== this.venue.logoUrl)
      ? this.droppedFile
      : null;
  }

  get qrCodeDroppedFile () {
    return (this.droppedFileQrCode && this.droppedFileQrCode.name !== this.venue.qrCodelogoUrl)
      ? this.droppedFileQrCode
      : null;
  }

  onQrCodeCreated (qrCode: IQrCode) {
    this.qrCode = qrCode;
  }

  async nextActionAndSaveVenue (nextAction: { disabled: boolean; action: Function }) {
    if (await this.saveVenue()) {
      nextAction.action();
    }
  }

  get venueAreaRef () {
    return (this.$refs.venueAreaStep as Vue).$refs.venueArea as Vue;
  }

  get venueAreaItemRef () {
    return this.venueAreaRef.$refs.venueAreaItem as Vue[];
  }

  get venueDataForm () {
    return (this.$refs.venueDataStep as Vue).$refs.venueDataForm;
  }

  get additionalFeatureRef () {
    return (this.$refs.venueAdditionalFeatureStep as Vue).$refs.venueAdditionalFeature;
  }

  get socialMediaLinksRef () {
    return (this.additionalFeatureRef as Vue).$refs.socialMediaLinks;
  }

  async validate () {
    let areaItemsValid = true;
    switch (this.activeStep) {
      case CreateStep.Addressdata:
        return await (this.venueDataForm as FormFunctions).validate(this.venue, ['basedata']);
      case CreateStep.Areas:
        for (const areaItemRef of this.venueAreaItemRef) {
          if (!await (areaItemRef as FormFunctions).validate((areaItemRef as VenuesAreaItem).area)) {
            areaItemsValid = false;
          }
        }
        return areaItemsValid;
      case CreateStep.AdditionalFeatures: {
        const linksAreValid = await (this.socialMediaLinksRef as FormFunctions).validate(this.venue, ['additionalFeaturesLinks']);
        const additionalFeatureIsValid = await (this.additionalFeatureRef as FormFunctions).validate(this.venue, ['additionalFeatures']);
        return linksAreValid && additionalFeatureIsValid;
      }
      case CreateStep.Options: {
        // have to get current ref (and not computed getter) because of mode switch vue rerender components
        const optionsCheckinCheckoutRef = ((this.$refs.venueOptionsStep as Vue).$refs.venueOptions as Vue).$refs.checkinCheckout;
        let hasCheckInOptionsIsValid = true;
        let userVisitOptionsIsValid = true;
        if (optionsCheckinCheckoutRef && (optionsCheckinCheckoutRef as any).canShowLimitField
        && this.venue.checkinMode === CheckInModes.CheckInCheckOut) {
          hasCheckInOptionsIsValid = await (optionsCheckinCheckoutRef as FormFunctions).validate(this.venue, ['options']);
        }
        const userVisitComponent = ((this.$refs.venueOptionsStep as Vue).$refs.venueOptions as VenuesOptions).$refs.userVisitContent as VenuesUserVisitContent;
        if (this.venue.isUserVisit && userVisitComponent) {
          const isUserVisitOptionsValid = await userVisitComponent.validate(this.venue, ['isUserVisit']);
          const isAgreementValid = userVisitComponent.validateAgreement();
          userVisitOptionsIsValid = isUserVisitOptionsValid && isAgreementValid;
        }
        return hasCheckInOptionsIsValid && userVisitOptionsIsValid;
      }
      default:
        return true;
    }
  }

  async saveVenue (navigateToOverView = false) {
    if (!await this.validate()) {
      return false;
    }
    this.loading = true;
    try {
      this.venue = await this.$venues.upsertVenue({
        venue: this.venue,
        origVenue: classToClass(new Venue()),
        dropFile: this.droppedFile,
        dropFileQrCode: this.droppedFileQrCode,
        dropMenuFile: this.droppedMenuFile
      });
      this.loading = false;
      let message = '';
      switch (this.activeStep) {
        case CreateStep.Addressdata:
          message = 'Die Adressdaten wurden angelegt.';
          break;
        case CreateStep.AdditionalFeatures:
          message = 'Die Zusatzfunktionen wurden gespeichert.';
          break;
        case CreateStep.QrCode:
          message = 'Die QR-Codeeinstellungen wurden gespeichert.';
          break;
        case CreateStep.OnlineFormular:
          message = 'Die Einstellungen zum Onlineformular wurden gespeichert.';
          break;
        case CreateStep.Options:
          message = 'Die Optionen wurden gespeichert.';
          break;
        case CreateStep.Areas:
          message = 'Die Standortbereiche wurden gespeichert.';
          break;
        default:
          throw new Error('Invalid active step');
      }

      this.$buefy.toast.open({
        duration: 5000,
        message: message,
        position: 'is-top',
        type: 'is-success'
      });
      if (this.activeStep === CreateStep.Addressdata && !this.$auth.ownedVenues.find(x => x.id === this.venue.id)) {
        // after first step save the new venue in owendVenus
        this.$auth.ownedVenues.unshift(this.venue);
      } else {
        // at all other steps have to replace this setted venue because the reference is lost after upsert
        const updateVenueIndex = this.$auth.ownedVenues.findIndex(v => v.id === this.venue.id);
        Vue.set(this.$auth.ownedVenues, updateVenueIndex, this.venue);
      }

      if (navigateToOverView) {
        this.isDirty = false;
        this.$router.push({ name: RouteNames.Venues });
      }
      return true;
    } catch (e) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger'
      });
      return false;
    }
  }
}
