import { IArea } from '@einfachgast/shared';
import { IsInt, IsNotEmpty, IsOptional } from 'class-validator';
import BaseModel from '@/models/base-model';
import { Expose, Transform } from 'class-transformer';

export class Area extends BaseModel<IArea> implements IArea {
  @Expose()
  public id: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Bitte geben Sie einen Namen an', groups: ['basedata'] })
  public name: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public label: string;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isDeactivated: boolean;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  @IsOptional()
  @IsInt()
  @IsNotEmpty({ message: 'Bitte geben Sie die maximale gleichzeitige Anzahl an Gästen am Bereich an.', groups: ['options'] })
  public limit: number;
}
