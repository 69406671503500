import { Transform, Expose, plainToClass } from 'class-transformer';
import { CheckInMode, CheckInModes, IArea, IPendingVisit, IVenue, IVenueStatsWrapper } from '@einfachgast/shared';
import { Area } from '@/models/area';
import { ArrayNotEmpty, IsEmail, IsInt, IsNotEmpty, IsOptional, IsUrl, MinLength, ValidateIf } from 'class-validator';
import BaseModel from '@/models/base-model';
import { ICovidOptions, IVenueStats } from '@/interfaces';
import { VenueStatsWrapper } from './venue-stats-wrapper';
import { getRandomId } from '@/helpers/qr-code-creation-helper';
import firebase from 'firebase/app';
import { CovidOptions } from './covid-opionts';

export class Venue extends BaseModel<IVenue> implements IVenue {
  static defaultWebFontColor = '#000000';
  static defaultWebBackgroundColor = '#4A90E2';
  static defaultFontColor = '#000000';
  static defaultBackgroundColor = '#ffffff';
  static defaultBorderColor = '#4A90E2';
  static defaultFooterFontColor = '#ffffff';
  static defaultFooterBackground = '#4A90E2';

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public id: string;

  @Expose()
  @Transform(value => value || Venue.defaultWebFontColor, { toClassOnly: true })
  public webFontColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultWebBackgroundColor, { toClassOnly: true })
  public webBackgroundColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultFontColor, { toClassOnly: true })
  public fontColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultBackgroundColor, { toClassOnly: true })
  public backgroundColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultBorderColor, { toClassOnly: true })
  public borderColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultFooterFontColor, { toClassOnly: true })
  public footerFontColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultFooterBackground, { toClassOnly: true })
  public footerBackground?: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public logoUrl?: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public qrCodelogoUrl?: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public name?: string;

  @Expose()
  @Transform(value => {
    if (typeof value === 'string') {
      // workaround for old string field values
      return {
        de: value,
        en: 'Welcome!\n\nPlease enter your contact information and have a safe and pleasent stay!'
      };
    }
    return value || {
      de: 'Herzlich willkommen!\n\nBitte tragen Sie zur Anmeldung Ihre Kontaktdaten ein, vielen Dank!',
      en: 'Welcome!\n\nPlease enter your contact information and have a safe and pleasent stay!'
    };
  }, { toClassOnly: true })
  public introText?: {[key: string]: string};

  @Expose()
  @Transform(value => value || {
    de: 'Aufgrund der Corona-Auflagen sind wir verpflichtet, Ihre Kontaktdaten und Ihren Aufenthalt zu dokumentieren. Bitte wenden Sie sich an unser Personal, wenn Sie kein Smartphone haben.',
    en: 'Due to the Corona requirements, we are obliged to document your contact details and your stay. Please contact the staff if you do not have a smartphone.'
  }, { toClassOnly: true })
  public qrCodeText?: {[key: string]: string};

  @Expose()
  @Transform(value => value || {
    de: 'Bitte lassen Sie diese Seite noch geöffnet, um unserem Servicepersonal die Datenerfassung zu bestätigen.',
    en: 'Please keep this page open to indicate your successful registration to our staff.'
  }, { toClassOnly: true })
  public scanAppFinishText?: {[key: string]: string};

  public ownerId: string;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isDeleted: boolean;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isDeactivated: boolean;

  @Expose()
  public stats: IVenueStats;

  constructor (data?: IVenue, public statsWrapper: IVenueStatsWrapper = new VenueStatsWrapper()) {
    super(data);
  }

  /**
   * This Lets you access The Visits and visitors history of the venue
   */
  get history (): IVenueStatsWrapper {
    return this.statsWrapper.wrap(this.stats);
  }

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Bitte geben Sie einen Namen an', groups: ['basedata'] })
  public label: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @MinLength(2, { message: (args) => `Mindestens ${args.constraints[0]} Zeichen`, groups: ['basedata'] })
  public street: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @MinLength(4, { message: (args) => `Mindestens ${args.constraints[0]} Zeichen`, groups: ['basedata'] })
  public zipCode: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @MinLength(3, { message: (args) => `Mindestens ${args.constraints[0]} Zeichen`, groups: ['basedata'] })
  public city: string;

  @Expose()
  @Transform(value => value?.map((x: IArea) => new Area(x)) || [new Area({ name: '', id: getRandomId(), isDeactivated: false, label: '', limit: null })], { toClassOnly: true })
  @ArrayNotEmpty({ message: 'Bitte legen Sie mindestens einen Bereich an' })
  public areas: Area[];

  @Expose()
  @Transform(value => value || [], { toClassOnly: true })
  @ArrayNotEmpty({ message: 'Bitte legen Sie mindestens einen Bereich an' })
  public pendingVisits: IPendingVisit[];

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @ValidateIf(o => o.facebookLink.length, { groups: ['additionalFeaturesLinks'] })
  @IsUrl({ protocols: ['http', 'https'] }, { message: 'Sie haben einen ungültigen Link eingegeben', groups: ['additionalFeaturesLinks'] })
  public facebookLink: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @ValidateIf(o => o.googleFeedbackLink.length, { groups: ['additionalFeaturesLinks'] })
  @IsUrl({ protocols: ['http', 'https'] }, { message: 'Sie haben einen ungültigen Link eingegeben', groups: ['additionalFeaturesLinks'] })
  public googleFeedbackLink: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Sie haben einen Dokumentnamen angegeben aber kein Dokument hochgeladen', groups: ['additionalFeatures'] })
  @ValidateIf(o => o.menuName.length, { groups: ['additionalFeatures'] })
  public menuUrl: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Sie haben einen Dokument hochgeladen aber aber kein Dokumentnamen angegeben', groups: ['additionalFeatures'] })
  @ValidateIf(o => o.menuUrl.length, { groups: ['additionalFeatures'] })
  public menuName: string;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public createdAt: firebase.firestore.Timestamp;

  @Expose()
  @Transform(value => value || 60, { toClassOnly: true })
  public duration: number;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public maxStayDuration?: number;

  @Expose()
  @Transform(value => value || CheckInModes.Standard, { toClassOnly: true })
  public checkinMode: CheckInMode;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public checkoutAllGuestsAt?: number;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public checkoutAllGuestsAtUTC?: number;

  @Expose()
  @Transform(value => { const nowDate = new Date(); return nowDate.getTimezoneOffset() / 60; }, { toClassOnly: true })
  public utcOffset?: number;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  @IsOptional()
  @IsInt()
  @IsNotEmpty({ message: 'Bitte geben Sie die maximale gleichzeitige Anzahl an Gästen am Standort an.', groups: ['options'] })
  public limit: number;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isUserVisit: boolean;

  @Expose()
  @Transform(value => value || 1, { toClassOnly: true })
  public deleteUserVisits: number;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsEmail({}, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein', groups: ['isUserVisit'] })
  @IsNotEmpty({ message: 'Bitte geben Sie die E-Mail des Datenschutzbeauftragten an.', groups: ['isUserVisit'] })
  public emailOfDataProtectionOfficer: string;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public orderProcessinAgreementAccepted: boolean;

  @Expose()
  @Transform(value => value ? new CovidOptions(value) : plainToClass(CovidOptions, ({} as any)), { toClassOnly: true })
  public covidOptions: ICovidOptions;

  getArea (areaId: string) {
    return this.areas.find(area => area.id === areaId);
  }

  validateArea (areaId: string) {
    return false;
  }

  isAreaActive (areaId: string) {
    return true;
  }
}
