













import Component, { mixins } from 'vue-class-component';
import { RevertUserAccount } from '@/mixins/revert-user-account';
import { IVenue } from '@einfachgast/shared';

@Component({
  name: 'DeletedAccountHint'
})
export default class DeletedAccountHint extends mixins(RevertUserAccount) {
  get venues () {
    return (this.$auth.ownedVenues as unknown as IVenue[]);
  }

  get mustShowDeleteHint () {
    return this.$auth.isVenueOwnerDeleted;
  }

  get DeleteInDaysText () {
    return this.deleteDateByLastVisit === '-'
      ? ''
      : `am ${this.deleteDateByLastVisit} endgültig`;
  }

  get deleteDateByLastVisit () {
    if (!this.$auth.venueOwner || this.venues.length === 0) {
      return '-';
    }
    const filteredVenues = this.venues
      .filter(x => x.history?.lastVisitDate !== null)
      .map(v => v.history.lastVisitDate.seconds);
    if (filteredVenues.length === 0) {
      return '-';
    }

    const lastVisitTimestamp = Math.max(...filteredVenues);
    if (!lastVisitTimestamp) {
      return '-';
    }
    const lastVisistDate = new Date(lastVisitTimestamp * 1000);
    const deleteDate = new Date();
    deleteDate.setDate(lastVisistDate.getDate() + 4 * 7); // 4 weeks after deletedAt
    return `${deleteDate.getDate()}.${deleteDate.getMonth() + 1}.${deleteDate.getFullYear()}`;
  }
}
