import { IDateHelper } from '../interfaces/i-date-helper';

export class DateHelper implements IDateHelper {
  // eslint-disable-next-line no-useless-constructor
  constructor (public date: Date = new Date()) {}

  get weekDay () {
    return this.date.getUTCDay();
  }

  get hours () {
    return this.date.getUTCHours();
  }

  get seconds () {
    return this.date.getUTCSeconds();
  }

  get minutes () {
    return this.date.getUTCMinutes();
  }

  get dayOfMonth () {
    return this.date.getUTCDate();
  }

  get month () {
    return this.date.getUTCMonth() + 1;
  }

  get year () {
    return this.date.getUTCFullYear();
  }

  get isMonday () {
    return this.date.getUTCDay() === 1;
  }

  get isFirstDayOfMonth () {
    return this.date.getUTCDate() === 1;
  }

  get isFirstDayOfYear () {
    return this.dayOfMonth === 1
      && this.month === 1;
  }

  get thisWeeksDays () {
    const curr = this.date;
    const first = curr.getDate() - curr.getDay();
    const days: Date[] = [new Date(curr.setDate(first))];
    for (let i = 1; i < 7; i++) {
      days.push(new Date(curr.setDate(curr.getDate() + 1)));
    }
    return days;
  }
}
