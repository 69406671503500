





























import Component from 'vue-class-component';
import Vue from 'vue';
import { routes } from '@/router/index';
import { RouteRecord } from 'vue-router';

@Component({
  name: 'SmallMenu'
})
export default class SmallMenu extends Vue {
  get routes () {
    return routes
      .filter(x => x.meta && (!!x.meta.menuTitle || !!x.meta.menuItemRenderer))
      .sort((a, b) => a.meta?.menuSortOrder - b.meta?.menuSortOrder);
  }

  isActive (route: RouteRecord) {
    return this.$route.path === route.path;
  }

  logout () {
    this.$auth.logout();
  }
}
