


























import Component from 'vue-class-component';
import MenuRendererAbstract from './abstract';

@Component({
  name: 'MyPlanMenuItemRenderer'
})
export default class MyPlanMenuItemRenderer extends MenuRendererAbstract {
  get badgeType () {
    if (this.$stripe.packageScope.isFreeProduct || this.$stripe.packageScope.Status === 'trialing') {
      return 'is-warning';
    }
    return 'is-primary';
  }

  get planName () {
    if (this.$stripe.packageScope.Status === 'trialing') {
      return 'Testphase';
    }
    return this.$stripe.packageScope.ProductName;
  }
}
