import { Match } from '@/decorators/match.decorator';
import { IRegistrationData } from '@/interfaces';
import { IVenueOwnerSettings } from '@einfachgast/shared';
import { IsEmail, MinLength } from 'class-validator';
import firebase from 'firebase/app';

export class RegistrationData implements IRegistrationData {
  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  companyName: string = null;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  firstname: string = null;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  lastname: string = null;

  @MinLength(5, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  phone: string = null;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  street: string = null;

  @MinLength(4, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  zipCode: string = null;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  city: string = null;

  country = 'DE';

  @IsEmail({}, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein' })
  email: string = null;

  @MinLength(6, { message: 'Das Passwort muss mindestens $constraint1 Zeichen lang sein' })
  password: string = null;

  @Match('password', { message: 'Die Passwörter stimmen nicht überein' })
  passwordConfirmation: string = null;

  settings: IVenueOwnerSettings = null;
  reCaptchaToken: string = null;
  stripeId: string = null;
  userId?: string;
  displayName?: string;
  photoUrl?: string;
  isDeleted = false;
  deletedAt: firebase.firestore.Timestamp = null;
  createdAt: firebase.firestore.Timestamp = null;
  lastSeenAt: firebase.firestore.Timestamp = null;
  trialEnd: firebase.firestore.Timestamp = null;
}
