import { Action, config, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import firebase from 'firebase/app';
import { IVenue, IPendingVisit, IVenueOwner } from '@einfachgast/shared';
import { ILoginData, IUpdateUnverifiedEmailRequest } from '@/interfaces';
import { firebaseWrapper as fb } from '@/firebase-wrapper';

import { VenueOwner } from '@/models/venue-owner';
import { Venue } from '@/models/venues/venue';
import { isEmail, isEmpty } from 'class-validator';
import { Roles } from '@/models/venues/roles';
import { PermissionResolver } from '@/helpers/permission-resolver';

config.rawError = true;

export interface IAuthStore {
  setUser: (user: firebase.User) => void;
  readonly user: firebase.User;
  readonly venueOwner: IVenueOwner;
  setVenueOwner: (venueOwner: IVenueOwner) => void;
  setIsVenueOwner: (isOwner: boolean) => void;
  setIAdmin: (isOwner: boolean) => void;
  readonly isVenueOwner: boolean;
  readonly isAdmin: boolean;
  readonly roles: Roles[];
  setIsVenueOwnerDeleted: (isOwnerDeleted: boolean) => void;
  readonly isVenueOwnerDeleted: boolean;
  setTrialExpiryDate: (expiryDate: Date) => void;
  readonly trialExpiryDate: Date;
  setIsBillingEnabled: (enabled: boolean) => void;
  readonly isBillingEnabled: boolean;
  setOwnedVenues: (venues: IVenue[]) => void;
  unsubscribeVenueSnapshot: () => void;
  readonly ownedVenues: IVenue[];
  readonly permissions: PermissionResolver;
  login: (loginData: ILoginData) => Promise<void>;
  logout: () => Promise<void>;
  reauthenticateUser: (currentPassword: string) => Promise<firebase.auth.UserCredential>;
  signup: (loginData: ILoginData) => Promise<void>;
  reInitApp: () => Promise<void>;
  disableVenueOwner: () => Promise<void>;
  updateUnverifiedUsersMailaddress: (newEmail: IUpdateUnverifiedEmailRequest) => Promise<string>;
  enableVenueOwner: () => Promise<void>;
  sendResetPasswordMail: (email: string) => Promise<void>;
  updateVenueOwner: (venueOwner: IVenueOwner) => Promise<void>;
  readonly isRegistrationComplete: boolean;
}

@Module({ namespaced: true, name: 'auth' })
export class AuthModule extends VuexModule {
  private _user: firebase.User = null;
  private _permissions = new PermissionResolver();
  private _isVenueOwner: boolean = null;
  private _isAdmin: boolean = null;
  private _isVenueOwnerDeleted: boolean = null;
  private _ownedVenues: IVenue[] = [];
  private _trialExpiryDate = new Date();
  private _isBillingEnabled = false;
  private _venueOwner: IVenueOwner = null;
  private _venueSnapshot: () => void = null;
  private _pendingVisitsSnapshot: () => void = null;

  get user () {
    return this._user;
  }

  get permissions () {
    return this._permissions;
  }

  @Mutation
  setUser (user: firebase.User) {
    this._user = user;
  }

  get venueOwner () {
    return this._venueOwner;
  }

  @Mutation
  setVenueOwner (venueOwner: IVenueOwner) {
    this._venueOwner = venueOwner;
  }

  get isVenueOwner () {
    return this._isVenueOwner;
  }

  get isAdmin () {
    return this._isAdmin;
  }

  get isVenueOwnerDeleted () {
    return this._isVenueOwnerDeleted;
  }

  @Mutation
  setIsVenueOwner (isOwner: boolean) {
    this._isVenueOwner = isOwner;
  }

  @Mutation
  setIsAdmin (isAdmin: boolean) {
    this._isAdmin = isAdmin;
  }

  @Mutation
  setIsVenueOwnerDeleted (isOwnerDeleted: boolean) {
    this._isVenueOwnerDeleted = isOwnerDeleted;
  }

  get venueSnapshot () {
    return this._venueSnapshot;
  }

  get pendingVisitsSnapshot () {
    return this._pendingVisitsSnapshot;
  }

  @Mutation
  setVenueSnapshotUnsubscribe (venueSnapshot: () => void) {
    this._venueSnapshot = venueSnapshot;
  }

  @Mutation
  setPendingVisitsSnapshoUnsubscribe (pendingVisistsSnapshot: () => void) {
    this._pendingVisitsSnapshot = pendingVisistsSnapshot;
  }

  get trialExpiryDate () {
    return this._trialExpiryDate;
  }

  @Mutation
  setTrialExpiryDate (date: Date) {
    this._trialExpiryDate = date;
  }

  get isBillingEnabled () {
    return this._isBillingEnabled;
  }

  @Mutation
  setIsBillingEnabled (enabled: boolean) {
    this._isBillingEnabled = enabled;
  }

  @Mutation
  updateVenueInOwnedVenues (venue: IVenue) {
    const updateVenueIndex = this._ownedVenues.findIndex(v => v.id === venue.id);
    if (updateVenueIndex !== -1) {
      const originalPendingVisists = this._ownedVenues[updateVenueIndex].pendingVisits;
      venue.pendingVisits = originalPendingVisists;
      this._ownedVenues.splice(updateVenueIndex, 1, venue);
    }
  }

  get ownedVenues () {
    return this._ownedVenues;
  }

  @Mutation
  setOwnedVenues (venues: IVenue[]) {
    this._ownedVenues = venues;
  }

  get isRegistrationComplete () {
    return !!this.user
      && this.user.emailVerified;
  }

  @Action
  async initApp () {
    const user = fb.auth.currentUser;
    this.context.commit('setUser', user);
    await this.context.dispatch('parseClaims', { user });
    await this.context.dispatch('initVenueOwner', user);
    await this.initOwnerVenues();
  }

  @Action
  async reInitApp () {
    await this.parseClaims({ user: fb.auth.currentUser, forceRefresh: true });
  }

  @Action
  async initVenueOwner () {
    if (!this.user) {
      this.context.commit('setVenueOwner', null);
      return;
    }
    try {
      const result = await fb.usersCollection.doc(this.user.uid).get();
      this.context.commit('setVenueOwner', new VenueOwner(result.data() as IVenueOwner));
    } catch {
      throw Error('Fetching user profile went wrong!');
    }
  }

  @Action
  async initOwnerVenues () {
    if (!this.user) {
      return;
    }
    try {
      const result = await fb.db.collection('venues')
        .where('ownerId', '==', this.user.uid)
        .get();
      const venues: IVenue[] = [];
      result.docs.forEach(async (value) => {
        const venueData = value.data() as IVenue;
        venueData.id = value.id;
        venues.push(new Venue(venueData));
      });
      const pendingVisitRef = fb.db.collection('pendingVisits')
        .where('ownerId', '==', this.user.uid);

      // inti pendingVisists to venue
      for (const venue of venues) {
        const pendingVisitsForVenue = await pendingVisitRef
          .where('venueId', '==', venue.id).get();
        venue.pendingVisits = pendingVisitsForVenue.docs.map(x => {
          return { id: x.id, ...x.data() };
        }) as IPendingVisit[];
      }

      this.unsubscribeVenueSnapshot();
      // add venueSnapshot to update visits and all venue things on-the-fly
      const venueRef = fb.db.collection('venues')
        .where('ownerId', '==', this.user.uid);
      const snapUnsubscribe = venueRef.onSnapshot((snap) => {
        snap.docChanges().forEach(async (venue) => {
          const venueData = venue.doc.data() as IVenue;
          venueData.id = venue.doc.id;
          const venueInstance = new Venue(venueData);
          this.updateVenueInOwnedVenues(venueInstance);
        });
      });

      this.setVenueSnapshotUnsubscribe(snapUnsubscribe);

      const pendingVisistsSnapShotUnsubscribe = pendingVisitRef.onSnapshot((snap) => {
        snap.docChanges().forEach(async (pendingVisitChange) => {
          const pendingVisit = pendingVisitChange.doc.data() as IPendingVisit;
          pendingVisit.id = pendingVisitChange.doc.id;
          const catchedVenue = this.ownedVenues
            .find(x => x.id === pendingVisit.venueId);
          const catchedPendingVisitsIndex = catchedVenue?.pendingVisits
            .findIndex(x => x.visitId === pendingVisit.visitId);
          if (pendingVisitChange.newIndex === -1) {
            // remove pending visit
            catchedVenue.pendingVisits.splice(catchedPendingVisitsIndex, 1);
          } else if (catchedPendingVisitsIndex >= 0) {
            // already exists in pendingVisits
            catchedVenue.pendingVisits.splice(catchedPendingVisitsIndex, 1, pendingVisit);
          } else {
            // a new pendingVisit
            catchedVenue.pendingVisits.unshift(pendingVisit);
          }
        });
      });
      this.setPendingVisitsSnapshoUnsubscribe(pendingVisistsSnapShotUnsubscribe);

      this.context.commit('setOwnedVenues', venues);
    } catch (e) {
      console.log(e);
      throw Error('Fetching user venues went wrong!');
    }
  }

  @Action
  unsubscribeVenueSnapshot () {
    this._venueSnapshot && this._venueSnapshot();
    this._pendingVisitsSnapshot && this._pendingVisitsSnapshot();
  }

  // actions
  @Action
  async login (loginData: ILoginData) {
    const { user } = await fb.auth.signInWithEmailAndPassword(loginData.email, loginData.password);
    await this.context.dispatch('parseClaims', { user });
    // store user object
    this.context.commit('setUser', user);
  }

  @Action
  async parseClaims (payload?: { user: firebase.User; forceRefresh?: boolean }) {
    // Check claims
    const token = await payload?.user?.getIdTokenResult(payload?.forceRefresh);
    this.context.commit('setIsVenueOwner', token?.claims.isVenueOwner);
    this._permissions.setRolesByTokenClaims(token?.claims);
    this.context.commit('setIsVenueOwnerDeleted', token?.claims.isDeleted);
  }

  @Action
  async signup (registrationData: ILoginData) {
    // sign user up
    const userRegistration = fb.functions.httpsCallable('registerVenueOwner');
    await userRegistration(registrationData);

    // log user in
    await fb.auth.signInWithEmailAndPassword(registrationData.email, registrationData.password);
    const user = fb.auth.currentUser;
    await this.context.dispatch('parseClaims', { user });

    // send verification mail to user
    const parsedUrl = new URL(window.location.href);
    user.sendEmailVerification({ url: `${parsedUrl.protocol}//${parsedUrl.host}` });

    this.context.commit('setUser', user);
  }

  @Action
  async logout () {
    await fb.auth.signOut();
    await this.context.dispatch('parseClaims', null);
    this.context.commit('setOwnedVenues', []);
  }

  @Action
  async updateUnverifiedUsersMailaddress (updateEmailRequest: IUpdateUnverifiedEmailRequest) {
    if (!this.user) {
      throw new Error('Sie haben nicht die nötigen Rechte.');
    }

    if (this.user.emailVerified) {
      throw new Error('Bereits verifizierte E-Mail-Adressen können nicht geändert werden.');
    }

    if (!isEmail(updateEmailRequest.email)) {
      throw new Error('Ungültige E-Mail-Adresse.');
    }

    if (isEmpty(updateEmailRequest.password)) {
      throw new Error('Zum aktualisieren der Email wird das passwort benötigt.');
    }

    // I need to re-Login the user with his old email to make sure the entered cretdentials are correct before updating the email
    await this.login({ password: updateEmailRequest.password, email: this.user.email });

    // Updating the email address
    const updateFunction = fb.functions.httpsCallable('updateUnverifiedEmail');
    await updateFunction(updateEmailRequest.email);

    // updating email automatically logs out the user. So i need to re-login again...
    await this.login(updateEmailRequest);

    // send verification mail to user
    const parsedUrl = new URL(window.location.href);
    return this.user.sendEmailVerification({ url: `${parsedUrl.protocol}//${parsedUrl.host}` });
  }

  @Action
  async updateVenueOwner (data: IVenueOwner) {
    const updateUserAccount = fb.functions.httpsCallable('updateUserAccount');
    await updateUserAccount(data);
    const docref = await fb.usersCollection.doc(this._user.uid);
    const result = await docref.get();
    this.context.commit('setVenueOwner', new VenueOwner(result.data() as IVenueOwner));
  }

  @Action
  async disableVenueOwner () {
    const disableUserAccount = fb.functions.httpsCallable('disableUserAccount');
    await disableUserAccount(this._user.uid);
  }

  @Action
  async enableVenueOwner () {
    const enableUserAccount = fb.functions.httpsCallable('enableUserAccount');
    await enableUserAccount(this._user.uid);
  }

  @Action
  async sendResetPasswordMail (email: string) {
    return await fb.auth.sendPasswordResetEmail(email);
  }

  @Action
  async reauthenticateUser (currentPassword: string) {
    // related to https://medium.com/@ericmorgan1/change-user-email-password-in-firebase-and-react-native-d0abc8d21618
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return await user.reauthenticateWithCredential(cred);
  }
}
