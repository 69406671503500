export default {
  step: 'Step',
  myData: 'My Data',
  registration: 'REGISTRATION',
  howItWorks: 'How it works',
  scanQrCode: 'Scan the QR code',
  enterContactDetails: 'Enter contact details',
  ready: 'Ready',
  browser: 'Browser',
  encryptedDataTransmissionInfo: 'Encrypted data transmission &amp; storage<br/>DSGVO-compliant, server in Germany',
  qrCodeIsNoIntendedForCustomers: 'This QR code is <strong>not</strong> intended for your customers.<br/>When a customer does not have a QR code capable device, an employee may check-in the customer with the help of this QR code.',
  deLang: 'Deutsch',
  enLang: 'English',
  esLang: 'Español',
  ruLang: 'русский',
  frLang: 'Français',
  trLang: 'Türk'
};
