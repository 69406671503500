






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router';
import { IVenue } from '@einfachgast/shared';
import { DashboardChartHelper } from '@/helpers/dashboard-chart-helper';
import VenueTableRowPrecent from '@/components/dashboard/venue-table-row-percent.vue';

@Component({
  name: 'VenueTableRow',
  components: {
    VenueTableRowPrecent
  },
  filters: {
    formatNumber (number: number) {
      return new Intl.NumberFormat([], {}).format(number);
    }
  }
})
export default class VenueTableRow extends Vue {
  dashboardChartHelper = new DashboardChartHelper();

  @Prop()
  venue: IVenue;

  get nowDate () {
    const nowDate = new Date();
    nowDate.setHours(0, 0, 0, 0); // reset to midnight
    nowDate.setDate(nowDate.getDate() - 1);
    return nowDate;
  }

  get visitorsAvgPerDay () {
    return this.dashboardChartHelper.getVisitorAvgPerDay(this.venue, this.nowDate);
  }

  get visitorsAvgLastSevenDays () {
    return this.dashboardChartHelper.getAverageLastXDaysWihtPercent(this.venue, 7, this.nowDate);
  }

  get visitorsAvgLastThirtyDays () {
    return this.dashboardChartHelper.getAverageLastXDaysWihtPercent(this.venue, 30, this.nowDate);
  }

  routeToEdit () {
    this.$router.push({ name: RouteNames.EditVenue, params: { venueId: this.venue.id } });
  }
}
