
























































































import { Component, Prop } from 'vue-property-decorator';
import { IArea, IVenue, CheckInModes } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import { QrCodePrintTypes } from '@/models/qr-code-print-types';
import { IQrCode } from '@/interfaces/i-qr-code';

@Component({
  name: 'VenuesAreaItem'
})
export default class VenuesAreaItem extends mixins(FormFunctions) {
  loading = false;
  hasChanged = false;
  canShowLimitField = false;

  qrCode: IQrCode = null;

  @Prop()
  qrCodes: IQrCode[];

  @Prop()
  index: number;

  @Prop()
  area: IArea;

  @Prop()
  venue: IVenue;

  get isAreaDeactivated () {
    return this.area.isDeactivated;
  }

  get venueCheckInModes () {
    return CheckInModes;
  }

  async mounted () {
    this.canShowLimitField = this.area.limit > 0 && CheckInModes.CheckInCheckOut === this.venue.checkinMode;

    this.loading = true;
    try {
      this.qrCode = this.qrCodes.find(x => x.venueId === this.venue.id && x.areaId === this.area.id);
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  changeLimitationSwitch () {
    if (!this.canShowLimitField) {
      this.area.limit = null;
    }
  }

  nameChanged () {
    this.clearErrors('name');
    this.hasChanged = true;
  }

  deleteArea () {
    this.$emit('deleteArea', this.area.id);
  }

  async createQrCodeForPrint () {
    try {
      if (this.isAreaDeactivated || !await this.validate(this.area)) {
        return;
      }
      await this.createQrCode();
      this.$emit('createQrCode', QrCodePrintTypes.Single, [this.area]);
    } catch {
      throw new Error('Error on QR-Code printing');
    }
  }

  async createQrCodeForSvg () {
    try {
      if (this.isAreaDeactivated || !await this.validate(this.area)) {
        return;
      }
      await this.createQrCode();
      this.$emit('createSvgQrCode', this.area);
    } catch {
      throw new Error('Error on QR-Code download');
    }
  }

  async createQrCode () {
    try {
      this.loading = true;
      if (this.hasChanged) {
        // on click qrCodePrint save the area (only when changed)
        await this.$venues.updateVenue(this.venue);
        this.$buefy.toast.open({
          duration: 5000,
          message: `Der Standortbereich <b>${this.area.name}</b> wurde gespeichert.`,
          position: 'is-top',
          type: 'is-success'
        });
      }
      this.$emit('areaSaved', this.area);
      this.hasChanged = false;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger'
      });
    }
  }
}
