import { VueEnvHelper } from './helpers/vue-envvar-helper';

class StripeConfiguration {
  private _envHelper: VueEnvHelper

  constructor () {
    this._envHelper = new VueEnvHelper('_STRIPE');
  }

  public get StripeApiKey (): string {
    return this._envHelper.get('API_KEY');
  }
}

const stripeConfiguration = new StripeConfiguration();
export { stripeConfiguration };
