








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'VenueQrCodePrintLangSelect'
})
export default class VenueQrCodePrintLangSelect extends Vue {
  selectedLang = 'de';
  isActive = true;

  get availableLanguages () {
    return [
      {
        key: 'de',
        label: 'Deutsch'
      },
      {
        key: 'en',
        label: 'English'
      }
    ];
  }

  selectLanguage () {
    this.$emit('printQrCode', this.selectedLang);
  }
}
