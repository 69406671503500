









import Vue from 'vue';
export default class NotFound extends Vue {

}
