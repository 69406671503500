

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VenuesData from '@/components/venues/venues-data.vue';
import { IVenue } from '@einfachgast/shared';

@Component({
  name: 'VenueDataStep',
  components: {
    VenuesData
  }
})
export default class VenueDataStep extends Vue {
    @Prop()
    venue: IVenue;
}
