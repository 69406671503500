


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import QrCodeTab from '@/components/venues/venues-design/qr-code-tab.vue';
import VenueQrPrint from '@/components/venues/venue-qr-print.vue';
import VeneuPreviewIframe from '@/components/venues/venue-preview-iframe.vue';
import { IQrCode } from '@/interfaces/i-qr-code';
import { AreaQrCodeCssHelper } from '@/helpers/area-qr-code-css-helper';
import { Area } from '@/models/area';

@Component({
  name: 'VenueQrCodeStep',
  components: {
    QrCodeTab,
    VenueQrPrint,
    VeneuPreviewIframe
  }
})
export default class VenueQrCodeStep extends Vue {
  qrCode: IQrCode = null;
  droppedFileQrCode: File = null;
  displayLanguage = this.$auth.venueOwner.settings.defaultLanguage;

  @Prop()
  venue: IVenue;

  setLanguage (lang: string) {
    this.displayLanguage = lang;
  }

  onDropFileQrCode (droppedFileQrCode: File) {
    this.droppedFileQrCode = droppedFileQrCode;
    this.$emit('onDropFileQrCode', droppedFileQrCode);
  }

  onQrCodeCreated (qrCode: IQrCode) {
    this.qrCode = qrCode;
    this.$emit('onQrCodeCreated', qrCode);
  }

  get qrCodeDroppedFile () {
    return (this.droppedFileQrCode)
      ? this.droppedFileQrCode
      : null;
  }

  get iframeStyles () {
    return AreaQrCodeCssHelper.getPrintCss(this.venue, this.$stripe.packageScope.hasDesignPackage);
  }

  get area () {
    return new Area({
      id: '0',
      name: 'Bereichsname',
      isDeactivated: false,
      label: 'Bereichslabel',
      limit: null
    });
  }
}
