import { ArrayWhitelist } from '@/decorators/array-whitelist';
import { IsInOtherProperty } from '@/decorators/is-in-other-property';
import { IVenueOwnerSettings } from '@einfachgast/shared';
import { Expose, Transform } from 'class-transformer';
import { ArrayMinSize, IsNotEmpty } from 'class-validator';
import { AvailableLanguages } from '@/enums/available-languages';
import BaseModel from './base-model';

export class VenueOwnerSettings extends BaseModel<IVenueOwnerSettings> implements IVenueOwnerSettings {
  @Expose()
  @Transform(value => value || ['de'], { toClassOnly: true })
  @ArrayMinSize(1, { message: 'Es muss min. eine Sprache ausgewählt sein.' })
  @ArrayWhitelist(AvailableLanguages.map(l => l.key), { message: 'Ungültige Sprache(n) ausgewählt' })
  activeLanguages: string[];

  @Expose()
  @Transform(value => value || 'de', { toClassOnly: true })
  @IsNotEmpty()
  @IsInOtherProperty('activeLanguages', { message: 'Standardsprache ist nicht in den aktiven Sprachen enthalten' })
  defaultLanguage: string;
}
