



















































import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';

@Component({
  name: 'VenuesUserVisitContent'
})
export default class VenuesUserVisitContent extends mixins(FormFunctions) {
  orderProcessingAgreementErrorMsg: string = null;

  @Prop()
  venue: IVenue;

  validateAgreement () {
    if (!this.venue.orderProcessinAgreementAccepted) {
      this.orderProcessingAgreementErrorMsg = 'Bitte akzeptieren Sie die Vereinbarung zur Auftragsverarbeitung';
      return false;
    }
    return true;
  }

  get orderProcessingAgreementLink () {
    return `https://einfachgast.de/auftragsverarbeitungsvertrag/?egVenue=${this.venue.id}`;
  }
}
