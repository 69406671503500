









import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'StripeRewrite'
})
export default class StripeRewrite extends Vue {
  loading = true;

  mounted () {
    try {
      this.$stripe.startStripeSession({ returnUrl: window.location.origin })
        .then((stripeSessionUrl: string) => {
          window.location.assign(stripeSessionUrl);
        });
    } catch (e) {
      this.$router.push({ path: '*' });
    }
  }
}
