
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { CreateElement } from 'vue/types/umd';
import { CombinedVueInstance } from 'vue/types/vue';

@Component({
  name: 'VeneuPreviewIframe'
})
export default class VeneuPreviewIframe extends Vue {
  @Prop()
  venue: IVenue;

  iFrame: CombinedVueInstance<Vue, {
      children: (readonly VNode[])[];
    }, object, object, Record<never, VeneuPreviewIframe>>;

  render (h: CreateElement) {
    return h('iframe', {
      on: {
        load: this.renderChildren
      }
    });
  }

  beforeUpdate () {
    // freezing to prevent unnessessary Reactifiation of vNodes
    if (this.iFrame) {
      // There's no other way
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.iFrame as any).children = Object.freeze(this.$slots.default);
    }
  }

  renderChildren () {
    const children = this.$slots.default;
    if (!this.$el) {
      return;
    }
    const body = (this.$el as HTMLIFrameElement).contentDocument.body;

    const el = document.createElement('div'); // we will mount or nested app to this element
    body.appendChild(el);
    // Watch Datachanges and make a Update
    const iFrame = new Vue({
      i18n: this.$i18n,
      name: 'IFrame',
      data: {
        children: [Object.freeze(children)]
      },
      provide: this.$root.$options.provide,
      render (h) { return h('div', (this as unknown as VNode).children); }
    });
    iFrame.$mount(el, true); // mount into iframe

    this.iFrame = iFrame; // cache instance for later updates
  }
}
