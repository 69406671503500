import { Roles } from '@/models/venues/roles';

export class PermissionResolver {
  private _roles: Roles[] = [];

  public setRolesByTokenClaims (tokenClaims: {[key: string]: any}) {
    if (tokenClaims?.isAdmin) {
      this._roles.push(Roles.isAdmin);
    }
  }

  public has (role: Roles) {
    return this._roles.indexOf(role) !== -1;
  }
}
