














import Component from 'vue-class-component';
import Vue from 'vue';
import { Product } from '@/models/stripe/product';
import { RouteNames } from '@/router';

@Component({
  name: 'FreePlanHint'
})
export default class FreePlanHint extends Vue {
  loading = false;
  products: Product[] = []

  get restVisitsCount () {
    return this.$auth.venueOwner?.remainingVisits || 0;
  }

  /**
   * The meaning of the sentences changes depending on the history of the user profile.
   * When the user has been on a plan, we can display `i18n("anymore")`. But when the
   * user has never booked any plan this would sound a bit off.
   */
  get Anymore () {
    // return this.$auth.venueOwner.packageHistory.length > 0
    //   ? 'mehr'
    //   : ''
    // ;

    // For now, always return ""
    return '';
  }

  get IsVisible () {
    return this.IsUserOwnFreeProductPlan
      && this.IsRegistrationComplete
      && !this.IsAboPage
    ;
  }

  get IsUserOwnFreeProductPlan () {
    return this.$stripe.packageScope
      && this.$stripe.packageScope?.isFreeProduct;
  }

  get IsRegistrationComplete () {
    return this.$auth.venueOwner
      && this.$auth.isRegistrationComplete;
  }

  get IsAboPage () {
    return this.$route.name === RouteNames.Abonnement;
  }
}
