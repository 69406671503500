







































import { Component, Vue } from 'vue-property-decorator';
import LiveVisitorAreaItem from '@/components/live-visitors/live-visitor-area-item.vue';
import { CheckInModes, IArea, IPendingVisit } from '@einfachgast/shared';

@Component({
  name: 'LiveVisitors',
  components: {
    LiveVisitorAreaItem
  }
})
export default class LiveVisitors extends Vue {
  selectedVenueId: string = null;

  get limit () {
    return this.selectedVenue?.limit;
  }

  get selectedVenue () {
    return this.venuesSorted.find(x => x.id === this.selectedVenueId);
  }

  get filteredAreas () {
    return this.selectedVenue?.areas.filter((x: IArea) => x.isDeactivated === false);
  }

  get venuesSorted () {
    return this.$auth.ownedVenues.filter(x => x.isDeactivated === false && x.isDeleted === false && x.checkinMode === CheckInModes.CheckInCheckOut)
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  }

  get currentVisits () {
    return this.selectedVenue?.pendingVisits.reduce((a: number, b: IPendingVisit) => a + b.visitorsCount, 0);
  }

  get liveVisitsCount () {
    return (this.limit)
      ? `${this.currentVisits}/${this.limit}`
      : this.currentVisits;
  }

  created () {
    this.selectedVenueId = this.venuesSorted[0]?.id;
  }
}
