import { IVisit } from '@einfachgast/shared';
import humanizeDuration from 'humanize-duration';
import * as XLSX from 'xlsx';

const DOWNLOAD_CSV_KEYS: Array<{key: keyof IVisit; label: string}> = [
  {
    key: 'email',
    label: 'Email'
  },
  {
    key: 'firstname',
    label: 'Vorname'
  },
  {
    key: 'lastname',
    label: 'Nachname'
  },
  {
    key: 'zipcode',
    label: 'PLZ'
  },
  {
    key: 'city',
    label: 'Stadt'
  },
  {
    key: 'street',
    label: 'Straße'
  },
  {
    key: 'phone',
    label: 'Telefon'
  },
  {
    key: 'companions',
    label: 'Begleiter'
  },
  {
    key: 'start',
    label: 'Start'
  },
  {
    key: 'end',
    label: 'Ende'
  },
  {
    key: 'duration',
    label: 'Dauer'
  }
];

const DOWNLOAD_DATE_FIELD_KEYS = [
  'start',
  'end'
];

const DOWNLOAD_BOOLEAN_FIELD_KEYS = [
  'safetyHintsAccepted'
];

const forbiddenChars = ['=', '+', '-', '@'];

const trimLeftForbiddenChars = (value: string): string => {
  if (!value) {
    return '';
  }
  if (typeof value !== 'string') {
    return value;
  }
  const isInjected = forbiddenChars.includes(value.charAt(0));
  if (!isInjected) {
    return value;
  }
  return trimLeftForbiddenChars(value.slice(1));
};

const formatVisitDataForDownload = (visitData: IVisit[]) => {
  const records: any[] = [];
  if (visitData.some(x => x.displayedSafetyHints === true) && !DOWNLOAD_CSV_KEYS.find(x => x.key === 'safetyHintsAccepted')) {
    DOWNLOAD_CSV_KEYS.push({ key: 'safetyHintsAccepted', label: 'Warnhinweise akzeptiert' });
  }
  for (const visitorData of visitData) {
    const itemContent: {[key: string]: string} = {};
    for (const column of DOWNLOAD_CSV_KEYS) {
      if (Array.isArray(visitorData[column.key])) {
        itemContent[column.label] = (visitorData[column.key] as [])
          .map((x: any) => trimLeftForbiddenChars(`${x.name}|${x.phone}`)).join('||');
      } else if (column.key === 'duration' && visitorData.start) {
        if (!visitorData.end) {
          itemContent[column.label] = null;
        } else {
          const diffDurationTime = (visitorData.end as any)._seconds - (visitorData.start as any)._seconds;
          itemContent[column.label] = humanizeDuration(diffDurationTime * 1000, { language: 'de' });
        }
      } else if (DOWNLOAD_DATE_FIELD_KEYS.indexOf(column.key) !== -1 && visitorData[column.key]) {
        // date
        const timeZoneDate = new Date();
        const timeZoneOffsetInSeconds = timeZoneDate.getTimezoneOffset() * 60;
        const date = new Date(((visitorData[column.key] as any)._seconds - timeZoneOffsetInSeconds) * 1000);
        itemContent[column.label] = date.toISOString()
          .replace(/T/, ' ')
          .replace(/\..+/, '');
      } else if (DOWNLOAD_BOOLEAN_FIELD_KEYS.indexOf(column.key) !== -1 && visitorData[column.key]) {
        itemContent[column.label] = visitorData[column.key] ? 'Ja' : 'Nein';
      } else {
        itemContent[column.label] = trimLeftForbiddenChars(visitorData[column.key] as string);
      }
    }
    records.push(itemContent);
  }
  return records;
};

export function downloadAsExcel (visitData: IVisit[], filename: string) {
  if (visitData.length === 0) {
    return;
  }

  const excelRecords = formatVisitDataForDownload(visitData);

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(excelRecords, { dateNF: 'DD.MM.YYYY HH:mm:ss' });

  XLSX.utils.book_append_sheet(wb, ws);

  const range = XLSX.utils.decode_range(ws['!ref']);
  const c1 = XLSX.utils.decode_col('I'); // start col
  const c2 = XLSX.utils.decode_col('J'); // end col
  // set date format in excel file for start- and end-col
  for (let i = range.s.r + 1; i <= range.e.r; ++i) {
    const ref1 = XLSX.utils.encode_cell({ r: i, c: c1 });
    const ref2 = XLSX.utils.encode_cell({ r: i, c: c2 });

    if (ws[ref1].v) {
      ws[ref1].t = 'd';
      ws[ref1].z = 'DD.MM.YYYY HH:mm:ss';
    }

    if (ws[ref2].v) {
      ws[ref2].t = 'd';
      ws[ref2].z = 'DD.MM.YYYY HH:mm:ss';
    }
  }
  XLSX.writeFile(wb, `${filename}.xlsx`, { cellDates: true });
}

export function downloadAsCsv (visitData: IVisit[], filename: string) {
  if (visitData.length === 0) {
    return;
  }
  const records = formatVisitDataForDownload(visitData);
  let content = DOWNLOAD_CSV_KEYS.map(x => x.label).join(',') + '\n';
  for (const record of records) {
    content += Object.values(record).join(',') + '\n';
  }
  const blob = new Blob(
    [content],
    {
      type: 'data:text/csv;charset=utf-8'
    }
  );

  const linkElement = document.createElement('a');
  const url = URL.createObjectURL(blob);
  linkElement.setAttribute('href', url);
  linkElement.setAttribute('download', `${filename}.csv`);
  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false
  });

  linkElement.dispatchEvent(clickEvent);
}
