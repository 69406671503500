const MS = 1;
const MS_IN_SECOND = MS * 1000;
const MS_IN_MINUTE = MS_IN_SECOND * 60;
const MS_IN_HOUR = MS_IN_MINUTE * 60;
const MS_IN_DAY = MS_IN_HOUR * 24;

export class DurationFormatter {
  format (ms: number) {
    // format milliseconds to sec and create minutes, hours, days from sec
    // s = seconds; i = minutes; h = hours; d = days
    const d = Math.floor(ms / MS_IN_DAY);
    const h = Math.floor(ms / MS_IN_HOUR) - Math.round(d * MS_IN_DAY / MS_IN_HOUR);
    const i = Math.floor(ms / MS_IN_MINUTE) - Math.round(h * MS_IN_HOUR / MS_IN_MINUTE);
    const s = Math.floor(ms / MS_IN_SECOND) - Math.round(i * MS_IN_MINUTE / MS_IN_SECOND);

    if (d >= 1) {
      return `${d} T. ${h.toString().padStart(2, '0')} Std.`;
    } else if (h >= 1) {
      return `${h} Std. ${i.toString().padStart(2, '0')} Min.`;
    }

    return `${i} Min. ${s.toString().padStart(2, '0')} Sek.`;
  }

  formatSeconds (s: number) {
    return this.format(s * MS_IN_SECOND);
  }
}
