import { FirebaseConfiguration } from './classes/firebase-configuration';
import { VueEnvHelper } from './helpers/vue-envvar-helper';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';

class FirebaseWrapper {
  private _auth: firebase.auth.Auth = null
  private _firestore: firebase.firestore.Firestore = null
  private _functions: firebase.functions.Functions = null
  private _perf: firebase.performance.Performance = null
  private _storage: firebase.storage.Storage = null
  private fbConfig: FirebaseConfiguration

  constructor () {
    this.fbConfig = new FirebaseConfiguration();
  }

  analytics () {
    if (this.fbConfig.measurementId !== '') {
      firebase.analytics();
    }
  }

  async initialize () {
    if (this._firestore && this._functions && this._auth) {
      return;
    }

    firebase.initializeApp(this.fbConfig);
    const envHelper = new VueEnvHelper('_FIREBASE');

    this._auth = firebase.auth();
    this._firestore = firebase.firestore();
    this._functions = firebase.app().functions(envHelper.get('FUNCTIONS_REGION', 'europe-west1'));
    this._storage = firebase.storage();
    this._perf = firebase.performance();

    if (process.env.NODE_ENV !== 'production') {
      // Emulate Auth
      if (envHelper.get('EMULATOR_AUTH_ENABLED') === 'true') {
        const authSchema = envHelper.get('EMULATOR_AUTH_SCHEMA', 'http');
        const authHost = envHelper.get('EMULATOR_AUTH_HOST', 'localhost');
        const authPort = parseInt(envHelper.get('EMULATOR_AUTH_PORT', '9099'), 10);
        const authPath = envHelper.get('EMULATOR_AUTH_PATH', '/');
        const authUrl = new URL(`${authSchema}://${authHost}:${authPort}${authPath}`);
        this._auth.useEmulator(authUrl.toString());
      }

      // Emulate Firestore
      if (envHelper.get('EMULATOR_FIRESTORE_ENABLED') === 'true') {
        this._firestore.useEmulator(
          envHelper.get('EMULATOR_FIRESTORE_HOST', 'localhost'),
          parseInt(envHelper.get('EMULATOR_FIRESTORE_PORT', '8099'), 10)
        );
      }

      // Emulate Firebase Functions
      if (envHelper.get('EMULATOR_FUNCTIONS_ENABLED') === 'true') {
        this._functions.useEmulator(
          envHelper.get('EMULATOR_FUNCTIONS_HOST', 'localhost'),
          parseInt(envHelper.get('EMULATOR_FUNCTIONS_PORT', '5001'), 10)
        );
      }
    }
  }

  get auth () {
    return this._auth;
  }

  get db () {
    return this._firestore;
  }

  get functions () {
    return this._functions;
  }

  get perf () {
    return this._perf;
  }

  get storage () {
    return this._storage;
  }

  get usersCollection () {
    return this._firestore.collection('venueOwners');
  }

  get postsCollection () {
    return this._firestore.collection('posts');
  }

  get commentsCollection () {
    return this._firestore.collection('comments');
  }

  get likesCollection () {
    return this._firestore.collection('likes');
  }
}

const firebaseWrapper = new FirebaseWrapper();

export { firebaseWrapper };
