
























import { Component, Prop } from 'vue-property-decorator';
import { IQrCode } from '@/interfaces/i-qr-code';
import { mixins } from 'vue-class-component';
import { LanguageScope } from '@/mixins/language-scope';

@Component({
  name: 'VenueQrAdminPrint'
})
export default class VenueQrAdminPrint extends mixins(LanguageScope) {
  @Prop()
  qrCode: IQrCode;

  get qrCodeUrl () {
    return this.qrCode?.shortUrl.replace('http://', '');
  }
}
