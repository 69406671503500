










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import WebTab from '@/components/venues/venues-design/web-tab.vue';
import VenueWebFormPreview from '@/components/venues/venue-web-form-preview.vue';
import { IVenue } from '@einfachgast/shared';

@Component({
  name: 'VenueWebFormStep',
  components: {
    WebTab,
    VenueWebFormPreview
  }
})
export default class VenueWebFormStep extends Vue {
  droppedFile: File = null;
  displayLanguage = this.$auth.venueOwner.settings.defaultLanguage;

  @Prop()
  venue: IVenue;

  get webDroppedFile () {
    return (this.droppedFile)
      ? this.droppedFile
      : null;
  }

  setLanguage (lang: string) {
    this.displayLanguage = lang;
  }

  onDropFile (droppedFile: File) {
    this.droppedFile = droppedFile;
    this.$emit('onDropFile', droppedFile);
  }
}
