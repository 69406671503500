










import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { IPendingVisit } from '@/interfaces/i-pending-visit';
import { firebaseWrapper as fb } from '@/firebase-wrapper';

@Component({
  name: 'LiveVisitorAreaItemCheckoutBtn'
})
export default class LiveVisitorAreaItemCheckoutBtn extends Vue {
  checkoutLoading = false;

  @Prop()
  pendingVisit: IPendingVisit;

  async checkoutVisitor () {
    // checkout
    // here execute function to checkout a pendingVisit
    try {
      this.checkoutLoading = true;
      const checkout = fb.functions.httpsCallable('checkoutAdmin');
      await checkout([this.pendingVisit.id]);
      this.checkoutLoading = false;
    } catch {
      this.checkoutLoading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: `Fehler beim auschecken von: ${this.pendingVisit.name}!`,
        position: 'is-top',
        type: 'is-danger'
      });
    }
  }
}
