































import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { getVenueLogoUrl } from '@/helpers/venue-logo-helper';
import { Venue } from '@/models/venues/venue';
import { mixins } from 'vue-class-component';
import { LanguageScope } from '@/mixins/language-scope';

@Component({
  name: 'VenueWebFormPreview'
})
export default class VenueWebFormPreview extends mixins(LanguageScope) {
  logoUrl: string = null

  @Prop()
  venue: IVenue;

  @Prop()
  droppedFile: File;

  get hasDesignPackage () {
    return this.$stripe.packageScope.hasDesignPackage;
  }

  get canShowLogoFromData () {
    return this.logoUrl !== '' && this.logoUrl !== null && this.hasDesignPackage;
  }

  get canShowLogoFromUploadField () {
    return this.droppedFile && this.hasDesignPackage;
  }

  get styles () {
    if (!this.hasDesignPackage) {
      return `border: 10px solid ${Venue.defaultWebBackgroundColor}; color: ${Venue.defaultWebFontColor}; background: #fff`;
    }
    return `border: 10px solid ${this.venue.webBackgroundColor}; color: ${this.venue.webFontColor}; background: #fff`;
  }

  get droppedFileUrl () {
    if (!this.droppedFile) {
      return null;
    }
    return window.URL.createObjectURL(this.droppedFile);
  }

  get introText () {
    if (typeof this.venue.introText === 'object') {
      return this.venue.introText[this.displayLanguage] || this.venue.introText[this.$auth.venueOwner.settings.defaultLanguage];
    } else if (typeof this.venue.introText === 'string') {
      return this.venue.introText;
    }
    return '';
  }

  async mounted () {
    await this.setLogoUrl();
    this.$watch(() => this.venue.logoUrl, () => {
      this.setLogoUrl();
    });
  }

  async setLogoUrl () {
    if (this.venue.logoUrl !== '' && this.venue.id !== '' && !this.droppedFile) {
      this.logoUrl = await getVenueLogoUrl(this.venue);
    } else if (this.venue.id !== '') {
      this.logoUrl = null;
    }
  }
}
