


































































































import { Component, Vue } from 'vue-property-decorator';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import { downloadAsCsv, downloadAsExcel } from '@/helpers/visits-download-helper';

@Component({
  name: 'VisitorsDownload'
})
export default class VisitorsDownload extends Vue {
  loading = false;
  selectedVenueId: string = null;
  // buefyfix/workaround to select first entry (all venues)
  // https://github.com/buefy/buefy/issues/1084
  selectedAreaId = '0';
  selectedFrom: Date = new Date();
  selectedTo: Date = null;

  get venues () {
    return this.$auth.ownedVenues.filter(x => x.isUserVisit === true);
  }

  get selectableAreas () {
    if (!this.selectedVenueId) {
      return [];
    }
    const catchedVenue = this.venues.find(x => x.id === this.selectedVenueId);
    if (!catchedVenue) {
      return [];
    }
    return catchedVenue.areas;
  }

  mounted () {
    this.selectedFrom = new Date(new Date().setHours(0, 0, 0, 0));
    this.selectedVenueId = this.venues[0]?.id;
    const nowDateTo = new Date();
    nowDateTo.setHours(0, 0, 0, 0);
    nowDateTo.setMonth(nowDateTo.getMonth() + 1);
    this.selectedTo = nowDateTo;
  }

  async download (asExcel?: boolean) {
    this.loading = true;
    try {
      // download data as CSV
      const downloadVisitorDataFunc = fb.functions.httpsCallable('downloadVisitorData');
      this.selectedFrom.setHours(0, 0, 0);
      this.selectedTo.setHours(23, 59, 59);
      const downloadVisitorData = await downloadVisitorDataFunc({
        venueId: this.selectedVenueId,
        areaId: (this.selectedAreaId !== '0') ? this.selectedAreaId : null,
        from: this.selectedFrom.getTime(),
        to: this.selectedTo.getTime()
      });
      const selectedVenue = this.venues.find(x => x.id === this.selectedVenueId);
      if (!selectedVenue) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Der Standort wurde nicht gefunden. Versuchen Sie es später noch einmal.',
          position: 'is-top',
          type: 'is-danger'
        });
        return;
      }
      if (downloadVisitorData.data.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Leider sind für diesen Zeitraum keine Einträge vorhanden!',
          position: 'is-top',
          type: 'is-info'
        });
        this.loading = false;
        return;
      }
      if (asExcel === true) {
        downloadAsExcel(downloadVisitorData.data, selectedVenue.name);
      } else {
        downloadAsCsv(downloadVisitorData.data, selectedVenue.name);
      }
      this.loading = false;
    } catch {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Fehler beim Herunterladen der Besucherdaten aufgetreten. Bitte versuchen Sie es später noch einmal.',
        position: 'is-top',
        type: 'is-danger'
      });
      this.loading = false;
    }
  }
}
