import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import Subscription from '@/models/stripe/subscription';
import { Product } from '@/models/stripe/product';
import { loadStripe } from '@stripe/stripe-js';
import { StartSessionPayload } from '@/models/stripe/start-session-payload';
import { SubscribeProductPayload } from '@/models/stripe/susbcribe-product-payload';
import { stripeConfiguration } from '@/stripe';
import { IStripeStore } from '../interfaces/i-stripe-store';
import { SubscribeProductResponse } from '@/models/stripe/subscribe-product-response';
import { PackageScope } from '@/models/stripe/package-scope';
import { IVenueOwner } from '@einfachgast/shared';

@Module({ namespaced: true, name: 'stripe' })
export class StripeModule extends VuexModule implements IStripeStore {
  private _packageScope: PackageScope = new PackageScope();
  private _packageScopeLoading = false;

  @Mutation
  setPackageScope (packageScope: PackageScope) {
    this._packageScope = packageScope;
  }

  get packageScope () {
    return this._packageScope;
  }

  @Mutation
  setPackageScopeLoading (state: boolean) {
    this._packageScopeLoading = state;
  }

  get packageScopeLoading () {
    return this._packageScopeLoading;
  }

  get isSubscribedCustomProduct () {
    const user = this.context.rootGetters['auth/venueOwner'] as IVenueOwner;
    if (!this.packageScope?.visibility) {
      return false;
    }
    return this.packageScope.visibility.indexOf(user?.userId) !== -1;
  }

  @Action
  async startStripeSession (payload: StartSessionPayload): Promise<string> {
    const stripeSessionCall = fb.functions.httpsCallable('stripeSession');
    const stripeSession = await stripeSessionCall(payload);
    return stripeSession.data;
  }

  @Action
  async getSubscription (): Promise<Subscription> {
    const stripeSubscriptionInfoCall = fb.functions.httpsCallable('getSubscriptionInfos');
    const subscriptionInfos = await stripeSubscriptionInfoCall();
    return subscriptionInfos.data;
  }

  @Action
  async getAvailableProducts (): Promise<Product[]> {
    const stripeSessionCall = fb.functions.httpsCallable('getAvailableProducts');
    const stripeSession = await stripeSessionCall();
    return stripeSession.data;
  }

  @Action
  async subscribeProduct (payload: SubscribeProductPayload): Promise<SubscribeProductResponse> {
    const subscribeProductCall = fb.functions.httpsCallable('subscribeProduct');
    const subscribeProductResponse = await subscribeProductCall(payload);
    const stripeInstance = await loadStripe(stripeConfiguration.StripeApiKey);
    const subscriptionResponse = await stripeInstance.redirectToCheckout({
      sessionId: subscribeProductResponse.data
    });
    return subscriptionResponse;
  }

  @Action
  async changeSubscribedProduct (payload: SubscribeProductPayload): Promise<SubscribeProductResponse> {
    const changeSubscribedProductCall = fb.functions.httpsCallable('changeSubscribedProduct');
    const subscribeProductResponse = await changeSubscribedProductCall(payload);
    const stripeInstance = await loadStripe(stripeConfiguration.StripeApiKey);
    const subscriptionResponse = await stripeInstance.redirectToCheckout({
      sessionId: subscribeProductResponse.data
    });
    return subscriptionResponse;
  }

  @Action
  async getPackageScope (): Promise<PackageScope> {
    this.context.commit('setPackageScopeLoading', true);
    const getPackageScopeCall = fb.functions.httpsCallable('getPackageScope');
    const getPackageScopeResponse = await getPackageScopeCall();
    const packageScope = new PackageScope(getPackageScopeResponse.data);
    packageScope.TrialStart = new Date(packageScope.TrialStart);
    packageScope.TrialEnd = new Date(packageScope.TrialEnd);
    this.context.commit('setPackageScope', packageScope);
    this.context.commit('setPackageScopeLoading', false);
    return packageScope;
  }
}
