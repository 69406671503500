














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VenuesArea from '@/components/venues/venues-area.vue';
import { IVenue, IArea } from '@einfachgast/shared';

@Component({
  name: 'VenueAreasStep',
  components: {
    VenuesArea
  }
})
export default class VenueAreasStep extends Vue {
  @Prop()
  venue: IVenue;

  areaSaved (area: IArea) {
    this.$emit('areaSaved', area);
  }
}
