import { Match } from '@/decorators/match.decorator';
import { MinLength } from 'class-validator';

export class UserPasswordChange {
  @MinLength(6, { message: 'Das alte Passwort muss gesetzt sein' })
  oldPassword = '';

  @MinLength(6, { message: 'Das Passwort muss mindestens 6 Zeichen lang sein' })
  password = '';

  @Match('password', { message: 'Die Passwörter stimmen nicht überein' })
  passwordConfirmation = '';

  public hasChanges () {
    return this.oldPassword !== '' || this.password !== '' || this.passwordConfirmation !== '';
  }
}
