import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import { IVenueModule } from '../interfaces/i-venue-module';
import { IVenue } from '@einfachgast/shared';
import { getUploadFilePath, getVenueLogoPath, removeOldFile, removeVenueLogos } from '@/helpers/venue-logo-helper';
import { IUpsertVenue } from '../interfaces/i-upsert-venue';
import { IVenueUploadImageParam } from '../interfaces/i-venue-upload-image-param';
import { Venue } from '@/models/venues/venue';

@Module({ namespaced: true, name: 'venues' })
export class VenueModule extends VuexModule implements IVenueModule {
  @Action
  async upsertVenue (venueUpsert: IUpsertVenue): Promise<IVenue> {
    const oldLogoUrl = venueUpsert.origVenue.logoUrl;
    const oldLogoUrlQrCode = venueUpsert.origVenue.qrCodelogoUrl;
    const oldMenuUrl = venueUpsert.origVenue.menuUrl;
    if (venueUpsert.dropFileQrCode) {
      venueUpsert.venue.qrCodelogoUrl = venueUpsert.dropFileQrCode.name;
    }
    if (venueUpsert.dropFile) {
      venueUpsert.venue.logoUrl = venueUpsert.dropFile.name;
    }
    if (venueUpsert.dropMenuFile) {
      const fileEnding = venueUpsert.dropMenuFile.name.split('.').pop();
      venueUpsert.venue.menuUrl = `${venueUpsert.venue.menuName}.${fileEnding}`;
    }

    if (venueUpsert.venue.id && venueUpsert.venue.id !== '') {
      await this.updateVenue(venueUpsert.venue);
    } else {
      await this.insertVenue(venueUpsert.venue);
    }

    await this.uploadImage({
      image: venueUpsert.dropFile,
      venue: venueUpsert.venue,
      origVenue: venueUpsert.origVenue,
      isQrCodeImage: false,
      oldLogoUrl
    });
    await this.uploadImage({
      image: venueUpsert.dropFileQrCode,
      venue: venueUpsert.venue,
      origVenue: venueUpsert.origVenue,
      isQrCodeImage: true,
      oldLogoUrl: oldLogoUrlQrCode
    });
    await this.uploadFile({
      image: venueUpsert.dropMenuFile,
      venue: venueUpsert.venue,
      origVenue: venueUpsert.origVenue,
      oldLogoUrl: oldMenuUrl
    });

    return new Venue(venueUpsert.venue);
  }

  @Action
  async insertVenue (venue: IVenue): Promise<IVenue> {
    const venueCreation = fb.functions.httpsCallable('createVenue');
    const venueData = await venueCreation(venue);
    if (venueData) {
      venue.id = venueData?.data.id;
      venue.createdAt = venueData?.data.createdAt;
    }
    return venue;
  }

  @Action
  async updateVenue (venue: IVenue): Promise<IVenue> {
    const venueUpdate = fb.functions.httpsCallable('updateVenue');
    await venueUpdate(venue);
    return venue;
  }

  @Action
  async uploadFile (uploadData: IVenueUploadImageParam) {
    const storageRef = fb.storage.ref();
    if (uploadData.image) {
      const fileEnding = uploadData.image.name.split('.').pop();
      const uploadedFilePath = getUploadFilePath(uploadData.venue, 'menu', `${uploadData.venue.menuName}.${fileEnding}`);
      uploadData.venue.menuUrl = `${uploadData.venue.menuName}.${fileEnding}`;
      const imageRef = storageRef.child(uploadedFilePath);
      if (uploadData.oldLogoUrl && uploadData.oldLogoUrl !== '') {
        // delete old image (+ resized Images) when oldLogo exists
        await removeOldFile(uploadData.origVenue, storageRef, uploadData.oldLogoUrl);
      }
      imageRef.put(uploadData.image);
    }
  }

  @Action
  async uploadImage (uplodateData: IVenueUploadImageParam) {
    const image = uplodateData.image;
    const venue = uplodateData.venue;
    const isQrCodeImage = uplodateData.isQrCodeImage;
    const oldLogoUrl = uplodateData.oldLogoUrl;
    if (!image) {
      return;
    }

    // upload file AFTER venue save. because we need the venue.Id for the FilePath
    // venueId in path because you can change the venueName und then youe must rename all Files (= more $)
    const storageRef = fb.storage.ref();
    if (image) {
      // Problem when logoUrl Updated wird versucht die preview zu reloaded (vue)
      // aber zu diesem Zeitpunkt ist das Bild noch gar nicht hochgeladen -.-
      // irgendwie muss das preview nach dem Upload geupdated werden und nicht wnn sie die LogoUrl updated
      const imageRef = storageRef.child(getVenueLogoPath(venue, image.name, undefined, isQrCodeImage));
      // !Important: When change the default size of resized images, then you have to delete all resized images in Firebase
      // and resize all original images to the new default size (i don't know how)
      if (oldLogoUrl !== '') {
        // delete old image (+ resized Images) when oldLogo exists
        await removeVenueLogos(uplodateData.origVenue, storageRef, isQrCodeImage);
      }
      await imageRef.put(image);
      if (isQrCodeImage) {
        venue.qrCodelogoUrl = image.name;
      } else {
        venue.logoUrl = image.name;
      }
    } else if (!image && oldLogoUrl !== '') {
      // for ONLY DELETE THE IMAGE
      // @Action venue, storageRef, isQrCodeImage
      await removeVenueLogos(uplodateData.origVenue, storageRef, isQrCodeImage);
    }
  }

  @Action
  async deleteVenue (venue: IVenue) {
    const venueDelete = fb.functions.httpsCallable('deleteVenue');
    await venueDelete(venue);
  }
}
