






























import { Component, Prop } from 'vue-property-decorator';
import { FormFunctions } from '@/mixins/form-functions';
import VenuesOptions from '@/components/venues/venues-options.vue';
import { mixins } from 'vue-class-component';
import { IVenue } from '@einfachgast/shared';

@Component({
  name: 'VenuesOptionsStep',
  components: {
    VenuesOptions
  }
})
export default class VenuesOptionsStep extends mixins(FormFunctions) {
  @Prop()
  venue: IVenue;
}
