















































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Product } from '@/models/stripe/product';
import ProductView from './product-view.vue';

@Component({
  name: 'Products',
  components: {
    ProductView
  }
})
export default class ProductsView extends Vue {
  activeTab = 1 // 1 = montl; 6= half-year

  @Prop()
  products: Product[]

  get activePriceIntervalCount () {
    if (!this.$stripe.packageScope) {
      return null;
    }
    const catchedProduct = this.products.find(x => x.id === this.$stripe.packageScope?.ProductId);
    if (!catchedProduct) {
      return null;
    }
    const catchedPrice = catchedProduct.prices.find(x => x.id === this.$stripe.packageScope?.Price.id);
    if (!catchedPrice) {
      return null;
    }
    return catchedPrice.interval_count;
  }

  mounted () {
    if (this.activePriceIntervalCount) {
      // preselct tab when active subscription
      this.activeTab = this.activePriceIntervalCount;
    }
    this.$nextTick(this.adjustProductItemWidths);
    window.addEventListener('resize', this.adjustProductItemWidths);
  }

  productsByIntervalCount (intervalCount: number) {
    return this.products.filter(x => !!x.prices.find(p => p.interval_count === intervalCount));
  }

  toggleTab (tabIndex: number) {
    this.activeTab = tabIndex;
    this.$nextTick(this.adjustProductItemWidths);
  }

  adjustProductItemWidths () {
    const productEls = this.$el.querySelectorAll('.tab-content div.columns.is-active .product-item-wrapper');
    if (productEls) {
      const firstItem = productEls[0];
      for (const productElIndex in productEls) {
        if (productElIndex === '0' || !(productEls[productElIndex] instanceof HTMLElement) || !productEls[productElIndex]
        || firstItem.clientWidth === 0) {
          continue;
        }
        (productEls[productElIndex] as HTMLElement).style.maxWidth = `${firstItem.clientWidth}px`;
      }
    }
  }
}
