








































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IVenueOwnerSettings } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { AvailableLanguages } from '@/enums/available-languages';
import { isEqual } from 'lodash';

type Language = { key: string; label: string };

@Component({
  name: 'VenueLanguageSettings'
})
export default class VenueLanguageSettings extends mixins(FormFunctions) {
  filter = '';
  filteredLanguages: Language[] = [];
  selectedLanguages: Language[] = [];
  isDirty = false;
  initialSettings: IVenueOwnerSettings = null;

  @Prop()
  value: IVenueOwnerSettings;

  get Languages () {
    if (this.filter.length > 0) {
      return this.filteredLanguages;
    }
    return AvailableLanguages
      .filter(x => this.selectedLanguages.indexOf(x) === -1);
  }

  get LanguageCount () {
    return AvailableLanguages.length;
  }

  get LanguageTooltipText () {
    const list = AvailableLanguages.map(x => x.label);
    return `Verfügbare Sprachen: ${list.join(', ')}`;
  }

  created () {
    this.initialSettings = Object.assign({}, this.value);
  }

  mounted () {
    // Restore selected languages from settings.activeLanguages
    this.selectedLanguages = AvailableLanguages.filter((available) => {
      return this.value.activeLanguages.indexOf(available.key) > -1;
    });
  }

  activeLanguagesChanged () {
    this.filter = '';
    this.filteredLanguages.splice(0);
    this.value.activeLanguages = this.selectedLanguages.map(sl => sl.key);
    if (this.value.activeLanguages.indexOf(this.value.defaultLanguage) === -1) {
      this.value.defaultLanguage = null;
    }
    this.$emit('changed', !isEqual(this.value.activeLanguages.sort(), this.initialSettings.activeLanguages.sort()));
  }

  defaultLanguageChanged () {
    this.$emit('changed', !isEqual(this.value.defaultLanguage, this.initialSettings.defaultLanguage));
  }

  /**
   * Helper fn for autocompleting languages
   */
  filterLanguages (text: string) {
    this.filter = text;
    this.filteredLanguages = AvailableLanguages.filter((lang) => {
      return this.selectedLanguages.indexOf(lang) === -1
        && lang.label
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) > -1;
    });
  }
}
