import { IArea, IVenue } from '@einfachgast/shared';
import { QrCode } from '@/models/qr-code';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import { IQrCode } from '@/interfaces/i-qr-code';
import QRious from 'qrious/dist/qrious.js';
import { Venue } from '@/models/venues/venue';
import QRCode from 'qrcode-svg';
import { QrCodePrintTypes } from '@/models/qr-code-print-types';

export function getRandomId (len = 6) {
  len = Math.min(Math.max(len, 6), 8); // Enforce a min length of 6 and a max length of 8
  const maxlen = 8;
  const min = Math.pow(16, Math.min(len, maxlen) - 1);
  const max = Math.pow(16, Math.min(len, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < 6) {
    r = `${r}${getRandomId(len - maxlen)}`;
  }
  return r.toString();
}

export async function createQrCodeForArea (venue: IVenue, area: IArea, printType: QrCodePrintTypes) {
  const addQrCode = fb.functions.httpsCallable('addQrCode');
  let retryCount = 0;
  while (retryCount <= 3) {
    try {
      const shortId = getRandomId();
      await addQrCode({ venueId: venue.id, areaId: area.id, isAdminCode: printType === QrCodePrintTypes.Admin, customShortId: shortId });
      return new QrCode({ id: shortId, venueId: venue.id, areaId: area.id, isAdminCode: printType === QrCodePrintTypes.Admin });
    } catch (e) {
      // when code === 'already-exists' => make one more run
      // else => its a "REAL"-Error
      if (e.code === 'already-exists') {
        retryCount++;
      } else {
        return null;
      }
    }
  }
  return null;
}

export function createQrCodeImage (qrCode: IQrCode, venue: IVenue, printType: QrCodePrintTypes, hasDesignPackage?: boolean) {
  if (!qrCode.url) {
    return '';
  }

  const qr = new QRious({
    background: (printType === QrCodePrintTypes.Admin)
      ? '#fff'
      : (hasDesignPackage)
        ? venue.backgroundColor
        : Venue.defaultBackgroundColor,
    backgroundAlpha: 0.8,
    foreground: (printType === QrCodePrintTypes.Admin)
      ? '#000000'
      : (hasDesignPackage)
        ? venue.fontColor
        : Venue.defaultFontColor,
    foregroundAlpha: 0.8,
    level: 'H',
    size: 158,
    value: qrCode.url
  });
  return qr.image.outerHTML;
}

export function createQrCodeSvg (qrCode: IQrCode, venue: IVenue, area: IArea) {
  if (!qrCode.url) {
    return '';
  }

  const qrcode = new QRCode({
    content: qrCode.url,
    padding: 0,
    width: 256,
    height: 256,
    color: '#000000',
    background: '#ffffff',
    ecl: 'M'
  });
  // create SVG string
  const svg = qrcode.svg();

  // download the svg
  const fileName = `${venue.label.replace(/\s/g, '_')}_${area.name.replace(/\s/g, '_')}_qrCode.svg`;
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(svg));
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
