





















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VenueDataStep from '@/components/venues/venue-steps/venue-data-step.vue';
import { IVenue, CheckInModes } from '@einfachgast/shared';
import { Venue } from '@/models/venues/venue';
import { classToClass } from 'class-transformer';
import { IQrCode } from '@/interfaces/i-qr-code';
import VenueAdditionalFeaturesStep from '@/components/venues/venue-steps/venue-additional-features-step.vue';
import { CreateStep } from '@/models/venues/venue-steps';
import { FormFunctions } from '@/mixins/form-functions';
import VenuesAreaItem from './venues-area-item.vue';
import VenueAreasStep from '@/components/venues/venue-steps/venue-areas-step.vue';
import VenueQrCodeStep from '@/components/venues/venue-steps/venue-qr-code-step.vue';
import VenueWebFormStep from '@/components/venues/venue-steps/venue-web-form-step.vue';
import VenuesOptionsStep from '@/components/venues/venue-steps/venue-options-step.vue';
import { RouteNames } from '@/router';
import VenuesOptions from './venues-options.vue';
import VenuesUserVisitContent from './venues-options/venues-user-visit-content.vue';
import { isEqual } from 'lodash';
import { Route } from 'vue-router';

Component.registerHooks([
  'beforeRouteLeave'
]);

@Component({
  name: 'VenueEdit',
  components: {
    VenueDataStep,
    VenueAdditionalFeaturesStep,
    VenueAreasStep,
    VenueQrCodeStep,
    VenueWebFormStep,
    VenuesOptionsStep
  }
})
export default class VenueEdit extends Vue {
  loading = false;
  qrCode: IQrCode = null;
  droppedFile: File = null;
  droppedFileQrCode: File = null;
  droppedMenuFile: File = null;
  venue: IVenue = classToClass(new Venue());
  deepVenueCopy: IVenue = null;
  activeStep = CreateStep.Addressdata;
  addressHasError = false;
  areaHasError = false;
  linksHaveError = false;
  optionsHasError = false;
  additionalFeaturesHasError = false;
  isDirty = false;
  unwatchVenueWatch: () => void = null;

  @Prop()
  venueId: string;

  beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (this.isDirty) {
      this.$buefy.dialog.confirm({
        message: 'Es gibt ungespeicherte Änderungen auf dieser Seite.',
        cancelText: 'Seite verlassen',
        confirmText: 'Auf Seite bleiben',
        type: 'is-success',
        onConfirm: () => next(false),
        onCancel: () => next()
      });
    } else {
      next();
    }
  }

  created () {
    this.loading = true;
    this.loadVenue();
    this.loading = false;
  }

  mounted () {
    // have to set this here, because later (onSave) u must check agaibst original/old-data
    // e.x. to deltet old uploaded file
    this.deepVenueCopy = JSON.parse(JSON.stringify(this.venue));
    this.initVenueWatcher();
  }

  initVenueWatcher () {
    this.unwatchVenueWatch = this.$watch(() => this.venue, () => {
      this.isDirty = !isEqual(this.deepVenueCopy, JSON.parse(JSON.stringify(this.venue)));
    }, { deep: true });
  }

  // Return true when this.venue contains unsaved changes
  get venueIsDirty () {
    return this.isDirty || !isEqual(this.deepVenueCopy, JSON.parse(JSON.stringify(this.venue)));
  }

  loadVenue () {
    if (this.venueId && this.venueId !== '') {
      if (this.$auth.ownedVenues.length === 0) {
        return; // fix issue when user redirected after login (relogin) to venue Edit. The venues are not loaded at this time
      }
      this.venue = this.$auth.ownedVenues.find(ov => ov.id === this.venueId);
    }
  }

  onDropFile (droppedFile: File) {
    this.droppedFile = droppedFile;
  }

  onDropFileQrCode (droppedFileQrCode: File) {
    this.droppedFileQrCode = droppedFileQrCode;
  }

  onDropMenuFile (droppedMenuFile: File) {
    this.droppedMenuFile = droppedMenuFile;
  }

  get webDroppedFile () {
    return (this.droppedFile && this.droppedFile.name !== this.venue.logoUrl)
      ? this.droppedFile
      : null;
  }

  get qrCodeDroppedFile () {
    return (this.droppedFileQrCode && this.droppedFileQrCode.name !== this.venue.qrCodelogoUrl)
      ? this.droppedFileQrCode
      : null;
  }

  onQrCodeCreated (qrCode: IQrCode) {
    this.qrCode = qrCode;
  }

  get venueAreaRef () {
    return (this.$refs.venueAreaTab as Vue).$refs.venueArea as Vue;
  }

  get venueAreaItemRef () {
    return this.venueAreaRef.$refs.venueAreaItem as Vue[];
  }

  get venueDataForm () {
    return (this.$refs.venueDataStep as Vue).$refs.venueDataForm;
  }

  get venueOptionsRef () {
    return (this.$refs.venueOptionsTab as Vue).$refs.venueOptions;
  }

  get additionalFeatureRef () {
    return (this.$refs.venueAdditionalFeatureStep as Vue).$refs.venueAdditionalFeature;
  }

  get socialMediaLinksRef () {
    return (this.additionalFeatureRef as Vue).$refs.socialMediaLinks;
  }

  async validate () {
    let areaItemsValid = true;
    const addressIsValid = await (this.venueDataForm as FormFunctions).validate(this.venue, ['basedata']);
    this.addressHasError = !addressIsValid;

    let optionsIsValid = true;
    this.optionsHasError = false;
    let userVisitOptionsIsValid = true;
    // have to get current ref (and not computed getter) because of mode switch vue rerender components
    const optionsCheckinCheckoutRef = ((this.$refs.venueOptionsStep as Vue).$refs.venueOptions as FormFunctions).$refs.checkinCheckout;
    if (optionsCheckinCheckoutRef && (optionsCheckinCheckoutRef as any).canShowLimitField
    && this.venue.checkinMode === CheckInModes.CheckInCheckOut) { // only check if mode checkinCheckout selected
      optionsIsValid = await (optionsCheckinCheckoutRef as FormFunctions).validate(this.venue, ['options']);
      this.optionsHasError = !optionsIsValid;
    }

    const userVisitComponent = ((this.$refs.venueOptionsStep as Vue).$refs.venueOptions as VenuesOptions).$refs.userVisitContent as VenuesUserVisitContent;
    if (this.venue.isUserVisit && userVisitComponent) {
      const isUserVisitOptionsValid = await userVisitComponent.validate(this.venue, ['isUserVisit']);
      const isAgreementValid = userVisitComponent.validateAgreement();
      userVisitOptionsIsValid = isUserVisitOptionsValid && isAgreementValid;
      this.optionsHasError = !userVisitOptionsIsValid;
    }

    const additionalFeaturesIsValid = await (this.additionalFeatureRef as FormFunctions).validate(this.venue, ['additionalFeatures']);
    const linksAreValid = await (this.socialMediaLinksRef as FormFunctions).validate(this.venue, ['additionalFeaturesLinks']);

    for (const areaItemRef of this.venueAreaItemRef) {
      if (!await (areaItemRef as FormFunctions).validate((areaItemRef as VenuesAreaItem).area)) {
        areaItemsValid = false;
      }
    }
    this.additionalFeaturesHasError = !additionalFeaturesIsValid;
    this.areaHasError = !areaItemsValid;
    this.linksHaveError = !linksAreValid;

    return areaItemsValid && addressIsValid && additionalFeaturesIsValid && linksAreValid && optionsIsValid && userVisitOptionsIsValid;
  }

  async saveVenue () {
    if (!await this.validate()) {
      return false;
    }
    this.loading = true;
    try {
      if (this.unwatchVenueWatch) {
        // not catch changed on save
        this.unwatchVenueWatch();
      }
      this.venue = await this.$venues.upsertVenue({
        venue: this.venue,
        origVenue: this.deepVenueCopy,
        dropFile: this.droppedFile,
        dropFileQrCode: this.droppedFileQrCode,
        dropMenuFile: this.droppedMenuFile
      });
      const updateVenueIndex = this.$auth.ownedVenues.findIndex(v => v.id === this.venue.id);
      Vue.set(this.$auth.ownedVenues, updateVenueIndex, this.venue);
      this.deepVenueCopy = Object.assign({}, this.venue);
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Der Standort wurde gespeichert',
        position: 'is-top',
        type: 'is-success'
      });
      this.isDirty = false;
      this.initVenueWatcher();
      return true;
    } catch (e) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger'
      });
      return false;
    }
  }

  async deleteVenue () {
    this.$buefy.dialog.confirm({
      message: `<b>Möchten Sie den Standort "${this.venue.label}" wirklich löschen?</b><br/><br/>
      Sollten zu diesem Standort noch Gästedaten vorliegen, werden diese zusammen mit den Standortdaten erst nach Ablauf der gesetzlichen Vorgaben von 4 Wochen gelöscht.`,
      cancelText: 'abbrechen',
      onConfirm: async () => {
        this.loading = true;
        try {
          await this.$venues.deleteVenue(this.venue);
          this.$emit('deleteVenue', this.venue);
          this.venue.isDeleted = true;
          this.$router.push({ name: RouteNames.Venues });
          this.$buefy.toast.open({
            duration: 5000,
            message: `${this.venue.label} wurde erfolgreich gelöscht.`,
            position: 'is-top',
            type: 'is-success'
          });
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.message,
            position: 'is-top',
            type: 'is-danger'
          });
        }
        this.loading = false;
      }
    });
  }

  backToOverview () {
    this.$router.push({ name: RouteNames.Venues });
  }
}
