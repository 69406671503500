import visibility from 'vue-visibility-change';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase from 'firebase/app';

/**
 * Code for this Vue plugin was shamelessly stolen from
 * https://github.com/vv13/vue-authplugin/blob/master/src/index.ts
 */
const plugin = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install (Vue: any) {
    Vue.use(visibility);

    // Update lastSeenAt field every time the page visibility changes
    visibility.change(async () => {
      if (fb.auth.currentUser) {
        const doc = await fb.usersCollection.doc(fb.auth.currentUser.uid).get();
        await doc.ref.update({ lastSeenAt: firebase.firestore.Timestamp.now() });
      }
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (typeof window !== 'undefined' && (window as any).Vue) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Vue.use(plugin);
}

export { plugin as InteractionPlugin };
