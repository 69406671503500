




































































import { Component, Prop } from 'vue-property-decorator';
import { IArea, IVenue } from '@einfachgast/shared';
import { IQrCode } from '@/interfaces/i-qr-code';
import { getVenueLogoUrl } from '@/helpers/venue-logo-helper';
import { mixins } from 'vue-class-component';
import { LanguageScope } from '@/mixins/language-scope';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';

@Component({
  name: 'VenueQrPrint',
  components: {
    EinfachGastLogo
  }
})
export default class VenueQrPrint extends mixins(LanguageScope) {
  logoUrl: string = null

  @Prop()
  area: IArea;

  @Prop()
  qrCode: IQrCode;

  @Prop()
  venue: IVenue;

  @Prop()
  fullLogoUrl: string;

  @Prop()
  droppedFile: File;

  get qrCodeText () {
    return this.venue.qrCodeText[this.displayLanguage] || this.venue.qrCodeText[this.$auth.venueOwner.settings.defaultLanguage];
  }

  get droppedFileUrl () {
    if (!this.droppedFile) {
      return null;
    }
    return window.URL.createObjectURL(this.droppedFile);
  }

  get hasActiveDesignPackage () {
    return this.$stripe.packageScope?.isTrial || this.$stripe.packageScope?.hasDesignPackage;
  }

  get logoUrlDisplay () {
    return (this.fullLogoUrl)
      ? this.fullLogoUrl
      : this.logoUrl;
  }

  get qrCodeUrl () {
    return this.qrCode?.shortUrl.replace(/https?:\/\//gi, '');
  }

  async mounted () {
    await this.setLogoUrl();
    this.$watch(() => this.venue.qrCodelogoUrl, () => {
      this.setLogoUrl();
    });
  }

  async setLogoUrl () {
    if (this.logoUrl === null && this.venue.qrCodelogoUrl !== '' && this.venue.id !== '' && !this.droppedFile) {
      this.logoUrl = await getVenueLogoUrl(this.venue, undefined, true);
    } else if (this.venue.qrCodelogoUrl !== '' && this.venue.id !== '') {
      this.logoUrl = null;
    }
  }
}
