


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { VenueOptionsVsitiorEnum } from '@/models/venues/venue-options-visitor-enum';

@Component({
  name: 'VenuesOptionsFunctionItem'
})
export default class VenuesOptionsFunctionItem extends Vue {
  @Prop()
  venue: IVenue;

  @Prop()
  modus: VenueOptionsVsitiorEnum;

  @Prop()
  label: string;

  get isDisabled () {
    return this.$stripe.packageScope?.isFreeProduct && this.modus === VenueOptionsVsitiorEnum.visitorRegistration;
  }

  get isActive () {
    return this.venue.isUserVisit === (this.modus === VenueOptionsVsitiorEnum.visitorRegistration);
  }

  get radioModel () {
    return (this.venue.isUserVisit) ? VenueOptionsVsitiorEnum.visitorRegistration : VenueOptionsVsitiorEnum.contactDataAcquisition;
  }

  setFunctionMode () {
    if (this.isDisabled) {
      return;
    }
    this.venue.isUserVisit = (this.modus === VenueOptionsVsitiorEnum.visitorRegistration);
  }
}
