import { IUpdateUnverifiedEmailRequest } from '@/interfaces';
import { IsEmail, IsNotEmpty } from 'class-validator';

export class UpdateUnverifiedEmailRequest implements IUpdateUnverifiedEmailRequest {
  @IsEmail({}, { message: 'Ungültige E-Mail-Adresse' })
  email = '';

  @IsNotEmpty({ message: 'Bitte geben Sie Ihr Passwort an' })
  password = ''
}
