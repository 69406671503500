import { IVenue } from '@einfachgast/shared';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase from 'firebase/app';

export function getVenueLogoPath (venue: IVenue, customFileName?: string, small?: boolean, qrCodeLogo = false) {
  const smallImgSuffix = (small) ? '_1000x1000.' : '.';
  const prefFile = (qrCodeLogo)
    ? `${venue.id}/qrCode`
    : `${venue.id}`;
  const fileName = (customFileName)
    ? `${`${prefFile}/${customFileName}`}`
    : `${`${prefFile}/${(qrCodeLogo) ? venue.qrCodelogoUrl : venue.logoUrl}`}`;
  // when want the small image repace example.jpg with example_1000x1000.jpg
  return fileName.substring(0, fileName.lastIndexOf('.')) + smallImgSuffix + fileName.substring(fileName.lastIndexOf('.') + 1);
}

export function getUploadFilePath (venue: IVenue, prefix: string, customFileName?: string) {
  const prefFile = (prefix)
    ? `${venue.id}/${prefix}`
    : `${venue.id}`;

  const fileName = (customFileName)
    ? `${`${prefFile}/${customFileName}`}`
    : `${`${prefFile}/${venue.menuUrl}`}`;

  return fileName;
}

// when small file exists set this as logoURl else get the original one
// Because: onUpload image the logo will update. At this time the resized Image is not ready yet.
// On Next reload the resized image is ready
export async function getVenueLogoUrl (venue: IVenue, customFileName?: string, qrCodeLogo = false) {
  if ((qrCodeLogo && (venue.qrCodelogoUrl === '' || venue.qrCodelogoUrl === null))
    || (!qrCodeLogo && (venue.logoUrl === '' || venue.logoUrl === null))) {
    return null;
  }
  return await fb.storage.ref(getVenueLogoPath(venue, customFileName, undefined, qrCodeLogo)).getDownloadURL();
}

export async function removeOldFile (venue: IVenue, storageRef: firebase.storage.Reference, name: string) {
  const path = getUploadFilePath(venue, 'menu', name);
  try {
    const desertRef = storageRef.child(path);
    await desertRef.delete();
  } catch (error) {
    throw new Error(`Error on delete image: ${path}`);
  }
}

export async function removeVenueLogos (venue: IVenue, storageRef: firebase.storage.Reference, qrCodeLogo = false) {
  try {
    // remove small Image
    const desertRefSmall = storageRef.child(getVenueLogoPath(venue, null, true, qrCodeLogo));
    await desertRefSmall.delete();
    // remove original image
    const desertRef = storageRef.child(getVenueLogoPath(venue, null, false, qrCodeLogo));
    await desertRef.delete();
  } catch {
    throw new Error(`Error on delete image: ${getVenueLogoPath(venue, null, false, qrCodeLogo)}`);
  }
}
