import { store } from '@/store';
import { IStripeStore } from '@/store/interfaces/i-stripe-store';
import { StripeModule } from '@/store/modules/stripe';
import _Vue from 'vue'; // <-- notice the changed import
import { getModule } from 'vuex-module-decorators';

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function StripePlugin (Vue: typeof _Vue): void {
  Vue.prototype.$stripe = getModule(StripeModule, store);
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Vue {
    $stripe: IStripeStore;
  }
}
