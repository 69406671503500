import { IVenue } from '@einfachgast/shared';
import { Venue } from '@/models/venues/venue';

export class AreaQrCodeCssHelper {
  public static getPrintCss (venue: IVenue, hasDesingPackageOrIsTrial = false) {
    return `
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
        color-adjust: exact !important;                 /*Firefox*/
    }
    @page { size: A4 portrait; margin:0cm;}
    html {
        margin: 0;
    }
    body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
    }
    ${AreaQrCodeCssHelper.getCss(venue, hasDesingPackageOrIsTrial)}`;
  }

  public static getCss (venue: IVenue, hasDesingPackageOrIsTrial = false) {
    const fontColor = (hasDesingPackageOrIsTrial)
      ? venue.fontColor
      : Venue.defaultFontColor;
    const borderColor = (hasDesingPackageOrIsTrial)
      ? venue.borderColor
      : Venue.defaultBorderColor;
    const contentBackground = (hasDesingPackageOrIsTrial)
      ? venue.backgroundColor
      : Venue.defaultBackgroundColor;
    const footerBackground = (hasDesingPackageOrIsTrial)
      ? venue.footerBackground
      : Venue.defaultFooterBackground;
    const footerFontColor = (hasDesingPackageOrIsTrial)
      ? venue.footerFontColor
      : Venue.defaultFooterFontColor;

    const titleBackground = '#53b792';
    const titleColor = '#fff';
    return `
    .print-templ {
        font-family: 'Ubuntu', sans-serif;
        width: 94.5mm;
        height: 138mm;
        background: #fff;
        padding: 5mm;
        float: left;
        overflow:hidden;
    }
    .print-templ-inner {
        background-color: ${borderColor};
        color: ${fontColor};
        padding: 2mm;
    }
    .print-content {
        background: #fff;
        height: 125mm;
        background-color: ${contentBackground};
        position: relative;
    }
    .title {
        background: ${titleBackground};
        color: ${titleColor};
        text-align: center;
        height: 7mm;
        line-height: 7mm;
        font-weight: bold;
        font-size: 12pt;
        letter-spacing: 3px;
    }
    .title-arrow {
        margin: 0 auto;
        width: 0;
        height: 0;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        border-top: 14px solid ${titleBackground};
    }
    .print-templ:nth-child(4n+1) {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
    .print-templ:nth-child(4n+2) {
        border-bottom: 1px solid #ccc;
    }
    .print-templ:nth-child(4n+3) {
        border-right: 1px solid #ccc;
    }
    .area-title {
        position: absolute;
        bottom: 5px;
        color: ${fontColor};
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 8pt;
        font-weight: bold;
    }
    .bottom-dsgvo-wrapper {
        background-color: ${footerBackground};
        color: ${footerFontColor};
        height: 9mm;
        text-align: center;
        color: #fff;
        font-size: 6.5pt;
    }
    .bottom-dsgvo-wrapper .einfachgast-logo-bottom {
        float: left;
        width: 100px;
        padding: 12px;
        padding-right: 5px;
    }
    .bottom-dsgvo-wrapper .einfachgast-logo-bottom path {
        fill: ${footerFontColor};
    }
    .bottom-dsgvo-wrapper .dsgvo-text .mdi-lock-check-outline {
       height: 38px;
       font-size: 17px;
       float: left;
       margin-right: 3px;
    }
    .bottom-dsgvo-wrapper .dsgvo-text {
        text-align: left;
        margin: 0 auto;
        display: inline-block;
        padding: 6px 0px;
        color: ${footerFontColor};
        line-height: 12px;
    }
    .bottom-dsgvo-wrapper .dsgvo-text svg {
        fill: ${footerFontColor};
        float: left;
        width: 22px;
        height: 22px;
    }
    .bottom-dsgvo-wrapper.hasLogo .dsgvo-text {
        float: left;
    }
    .right-side-wrapper {
        width: 50%;
        float: right;
        color: ${fontColor};
    }
    .right-side-wrapper .qr-code-image img {
        margin-left: 15px;
        margin-top: 0px;
        width: 36mm;
        height: 36mm;
    }
    .right-side-wrapper .qr-code-image {
        padding: 5px;
    }
    .right-side-wrapper .qr-code-url {
        font-weight: bold;
        font-size: 7pt;
        text-align: center;
        margin-top: -4px;
        padding-left: 24px;
        padding-right: 18px;
        word-break: break-all;
    }
    .right-side-wrapper .description {
        font-size: 7pt;
        margin-top: 10px;
        padding-left: 24px;
        padding-right: 18px;
        line-height: 10pt;
    }
    .left-descr-wrapper {
        width: 50%;
        float: left;
        color: ${fontColor};
        text-align: center;
        font-weight: bold;
    }
    .left-description {
        width: 87px;
        margin: 0 auto;
    }
    .descr-title {
        font-size: 12pt;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .descr-gap {
        position: relative;
        background: ${fontColor};
        height: 2px;
        margin: 10px 0px;
    }
    .descr-text {
        margin: 15px 0px;
        font-size: 9pt;
    }
    .descr-gap .number {
        position: absolute;
        top: -10px;
        padding: 4px;
        background-color: ${contentBackground};
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 9pt;
    }
    .logo {
        padding: 4mm;
        height: 100px;
        width: 311px;
        text-align: center;
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .print-content .logo img {
        margin: auto;
        max-height: 100px;
        max-width: 312px;
    }
    .logo-title {
        color: #000000;
        height: 100px;
        page-break-after:auto;
        font-size: 30px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .logo-title span {
        vertical-align: middle;
    }
    .print-content .logo .main-logo-container svg {
        position: relative;
    }
    .print-content .logo svg, .print-content .logo .main-logo-container {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100px;
        max-width: 312px;
    }

    .admin-qr-content .print-content {
        background: #fff !important;
        color: #000000 !important;
        height: 133mm;
    }
    .admin-qr-content .print-templ-inner {
        background-color: #2080c5;
        color: #2080c5;
    }
    .admin-qr-content .qr-code-image {
        text-align: center;
    }
    .admin-qr-content .logo {
        height: 80px;
    }
    .admin-qr-content .qr-code-url {
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 10px;
        text-align: center;
    }
    .admin-qr-content .logo svg {
        height: 80px;
        position: relative;
    }
    .admin-descr-text {
        margin-top: 40px;
        text-align: center;
    }
    `;
  }
}
