























































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { IVenue } from '@einfachgast/shared';
import VenueEdit from '@/components/venues/venue-edit.vue';
import { RouteNames } from '@/router';

@Component({
  name: 'Venues',
  components: {
    VenueEdit
  }
})
export default class Venues extends mixins(FormFunctions) {
  dropFile: File = null;
  newVenue: IVenue = null;
  venues: IVenue[] = [];

  get hasNewVenue () {
    return this.venues.find(x => x.id === '');
  }

  get isOwnerDeleted () {
    return this.$auth.isVenueOwnerDeleted;
  }

  get venuesSorted () {
    return this.venues
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  }

  get addVenueTooltipLabel () {
    if (this.$stripe.packageScope?.isTrial) {
      return 'In der Testphase können keine weiteren Standorte mehr angelegt werden.';
    }
    return 'Mit Ihrem aktuellen Plan können keine weiteren Standorte mehr angelegt werden.';
  }

  get canAddNewVenue () {
    if (this.isOwnerDeleted || this.$stripe.packageScope?.maxVenueCount === 0) {
      return false;
    }
    if (this.$stripe.packageScope?.maxVenueCount === -1) {
      return true;
    }

    return this.availableVenues.length < this.$stripe.packageScope?.maxVenueCount;
  }

  get availableVenues () {
    return this.venues.filter(venue => !venue.isDeleted && !venue.isDeactivated);
  }

  created () {
    this.venues = this.$auth.ownedVenues.slice(0);
  }

  isVenueDisabledOrDeactivated (venue: IVenue) {
    return venue.isDeleted || venue.isDeactivated || this.isOwnerDeleted;
  }

  getDisabledVenueTooltipText (venue: IVenue) {
    if (venue.isDeactivated) {
      return 'Diese Standorte wurden deaktiviert, da die Anzahl an Standorten in Ihrem aktuellen Plan bereits überschritten ist.';
    }
    return 'Dieser Standort wurde als gelöscht markiert und wird nach Ablauf der gesetzlichen Vorgaben von 4 Wochen endgültig gelöscht.';
  }

  toggleCartItem (venue: IVenue) {
    if (this.isOwnerDeleted || venue.isDeleted) {
      return;
    }
    if (venue.id === '') {
      return;
    }
    this.$router.push({ name: RouteNames.EditVenue, params: { venueId: venue.id } });
  }

  addNewVenue () {
    this.$router.push({ name: RouteNames.NewVenue });
  }
}
