import { Transform, Expose } from 'class-transformer';
import BaseModel from '@/models/base-model';
import { ICovidOptions } from '@/interfaces';

export class CovidOptions extends BaseModel<ICovidOptions> implements ICovidOptions {
  @Expose()
  @Transform(value => value !== undefined && value !== null ? value : true, { toClassOnly: true })
  public showNoteText: boolean;

  @Expose()
  @Transform(value => value !== undefined && value !== null ? value : false, { toClassOnly: true })
  public showNegativeCheckbox: boolean;
}
