import { IsEmail, MinLength } from 'class-validator';
import BaseModel from '@/models/base-model';
import firebase from 'firebase/app';
import { Expose, plainToClass, Transform } from 'class-transformer';
import { VenueOwnerSettings } from './venue-owner-settings';
import { IVenueOwner, IVenueOwnerSettings } from '@einfachgast/shared';

export class VenueOwner extends BaseModel<IVenueOwner> implements IVenueOwner {
  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public companyName: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public firstname: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public lastname: string;

  @MinLength(5, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public phone: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public street: string;

  @MinLength(4, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public zipCode: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public city: string;

  @Expose()
  @Transform(value => value || 'DE', { toClassOnly: true })
  public country: string;

  @IsEmail({}, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein' })
  public email: string;

  @Expose()
  @Transform(value => value ? new VenueOwnerSettings(value) : plainToClass(VenueOwnerSettings, ({} as any)), { toClassOnly: true })
  public settings: IVenueOwnerSettings;

  public stripeId: string;
  public stripeLink: string;
  public subscriptionStartDate: firebase.firestore.Timestamp;
  public userId?: string;
  public isDeleted: boolean;
  public deletedAt: firebase.firestore.Timestamp;
  public createdAt: firebase.firestore.Timestamp;
  public lastSeenAt: firebase.firestore.Timestamp;
  public trialEnd: firebase.firestore.Timestamp;
  public remainingVisits?: number;
}
