



















import Vue from 'vue';
import Component from 'vue-class-component';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import Sidebar from '@/components/sidebar.vue';
import DeletedAccountHint from '@/components/hints/deleted-account-hint.vue';
import DeveloperHint from '@/components/hints/developer-hint.vue';
import FreePlanHint from '@/components/hints/free-plan-hint.vue';
import RemainingVisitsHint from '@/components/hints/remaining-visits-hint.vue';
import SetupHint from '@/components/hints/setup-hint.vue';
import TrialVersionHint from '@/components/hints/trial-version-hint.vue';
import { PackageScope } from './models/stripe/package-scope';
import { firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase from 'firebase/app';
import { IVenueOwner } from '@einfachgast/shared';
import { VenueOwner } from './models/venue-owner';

@Component({
  name: 'App',
  components: {
    DeveloperHint,
    DeletedAccountHint,
    EinfachGastLogo,
    FreePlanHint,
    RemainingVisitsHint,
    SetupHint,
    Sidebar,
    TrialVersionHint
  }
})
export default class App extends Vue {
  loading = true;
  licenseExpiry: Date = null;
  unsubscribeVenueOwnerListener: () => void = null;
  unsubscribeSubscriptionsListener: () => void = null;

  get isHideSidebar () {
    return this.$route.meta?.hideSidebar;
  }

  async mounted () {
    fb.auth.onAuthStateChanged((user: firebase.User) => {
      if (!user) {
        // on logout
        this.unsubscribeSnapshotListener();
        this.$auth.unsubscribeVenueSnapshot();
      }
    });
    // Muss hier so gemacht werden, da die App.vue gerendert wird, obwohl initApp (und somit initVenues) zu diesem Zeitpunkt noch nicht durch ist.
    // – OBWOHL (laut main.ts) ERST gerendert wird, wenn initApp fertig ist.
    // Dadruch sind am Anfang die Venues immer kurz leer und dadurch würde sonst immer der SetupHint für kurze Zeit angezeigt werden.
    if (this.$auth.venueOwner && this.$auth.isRegistrationComplete) {
      await this.initPackageScope();
    }
  }

  async initPackageScope () {
    // when venueOwner or subscription of venueOwner change => update the packageScope
    const docRef = fb.db.collection('venueOwners')
      .doc(this.$auth.venueOwner.userId);
    this.unsubscribeVenueOwnerListener = docRef.onSnapshot((venueOwnerRef) => {
      this.$stripe.getPackageScope().then((packageScopeResult) => {
        this.$stripe.setPackageScope(new PackageScope(packageScopeResult));
      });
      this.$auth.setVenueOwner(new VenueOwner(venueOwnerRef.data() as IVenueOwner));
    });

    this.unsubscribeSubscriptionsListener = docRef.collection('subscriptions').onSnapshot(() => {
      this.$stripe.getPackageScope().then((packageScopeResult) => {
        this.$stripe.setPackageScope(new PackageScope(packageScopeResult));
      });
    });
  }

  unsubscribeSnapshotListener () {
    if (this.unsubscribeVenueOwnerListener && this.unsubscribeSubscriptionsListener) {
      this.unsubscribeVenueOwnerListener();
      this.unsubscribeSubscriptionsListener();
      this.unsubscribeVenueOwnerListener = null;
      this.unsubscribeSubscriptionsListener = null;
    }
  }

  get showNav () {
    // No logged in User
    if (!this.$auth.user || !this.$auth.user.emailVerified) {
      return false;
    }

    return true;
  }
}
