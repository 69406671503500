






























































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { RevertUserAccount } from '@/mixins/revert-user-account';
import { UserPasswordChange } from '@/models/user-password-change';
import Subscription from '@/models/stripe/subscription';
import { getAllCountriesByLang } from '@/helpers/country-helper';

@Component({
  name: 'MyData'
})
export default class MyData extends mixins(FormFunctions, RevertUserAccount) {
  loading = false;
  userPasswordChange = new UserPasswordChange()
  subscription: Subscription = null

  get venueOwner () {
    return this.$auth.venueOwner;
  }

  get isUserDeleted () {
    return this.$auth.isVenueOwnerDeleted;
  }

  get allCountries () {
    return getAllCountriesByLang();
  }

  async mounted () {
    if (!this.isUserDeleted) {
      this.loading = true;
      if (!this.$stripe.packageScope.isFreeProduct) {
        this.subscription = await this.$stripe.getSubscription();
      }
      this.loading = false;
    }
  }

  async save () {
    if ((this.userPasswordChange.hasChanges() && !await this.validate(this.userPasswordChange))
      || !await this.validate(this.venueOwner)) {
      return;
    }
    this.loading = true;
    if (this.userPasswordChange.hasChanges()) {
      try {
        await this.$auth.reauthenticateUser(this.userPasswordChange.oldPassword);
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: (e.code === 'auth/wrong-password')
            ? 'Das alte Passwort ist nicht korrekt'
            : 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
          position: 'is-top',
          type: 'is-danger'
        });
        this.loading = false;
        return;
      }
    }

    try {
      await this.$auth.updateVenueOwner(this.venueOwner);
      if (this.userPasswordChange.hasChanges()) {
        await this.$auth.user.updatePassword(this.userPasswordChange.password);
        this.userPasswordChange = new UserPasswordChange();
      }
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Ihr Account wurde erfolgreich aktualisiert.',
        position: 'is-top',
        type: 'is-success'
      });
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger'
      });
    }
    this.loading = false;
  }

  async deleteAccount () {
    let message = '<b>Möchten Sie Ihr Konto wirklich löschen?</b><br/><br/>';
    // Der Type muss leider so doof convertiert werden!
    if (this.subscription && this.subscriptionDaysLeft(this.subscription.current_period_end as unknown as { _seconds: number }) > 0) {
      // have active Package
      message += `Ihr gebuchtes Paket <b>${this.$stripe.packageScope.ProductName}</b> läuft noch bis
        zum <b>${this.getFormattedVisitDate(this.subscription.current_period_end as unknown as { _seconds: number })}</b> und
        wird mit der Kontolöschung automatisch zu diesem Zeitpunkt gekündigt.
        <br/><br/>
        Sollten zu diesem Konto noch aktuelle Gästedaten vorliegen, werden diese zusammen mit den Kontodaten erst nach Ablauf der gesetzlichen Vorgaben von 4 Wochen endgültig gelöscht.
        <br/><br/>
        Bis zum Ablauf der gesetzlichen Vorgaben und bis zum Ablauf Ihres gebuchten Pakets, haben Sie weiterhin Zugriff auf dieses Konto.
      `;
    } else {
      // have no package
      message += `
        Sollten zu diesem Konto noch aktuelle Gästedaten vorliegen, werden diese zusammen mit den Kontodaten erst nach Ablauf der gesetzlichen Vorgaben von 4 Wochen endgültig gelöscht.
        <br/><br/>
        Solange haben Sie weiterhin eingeschränkten Zugriff auf dieses Konto. Sollten keine aktuellen Gästedaten vorliegen, wird das Konto sofort gelöscht.
      `;
    }
    this.$buefy.dialog.confirm({
      message: message,
      cancelText: 'abbrechen',
      onConfirm: async () => {
        this.loading = true;
        try {
          await this.$auth.disableVenueOwner();
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ihr Account wurde erfolgreich zur Löschung vorgemerkt.',
            position: 'is-top',
            type: 'is-success'
          });
          this.$auth.logout();
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.message,
            position: 'is-top',
            type: 'is-danger'
          });
        }
        this.loading = false;
      }
    });
  }

  subscriptionDaysLeft (currentPeriodEnd: { _seconds: number }) {
    return Math.floor((Date.now() - (currentPeriodEnd._seconds * 1000)) / (1000 * 60 * 60 * 24)) * -1;
  }

  getFormattedVisitDate (visitDate: { _seconds: number }) {
    return visitDate
      ? new Date(visitDate._seconds * 1000).toLocaleTimeString([], {
        year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit'
      })
      : '-';
  }
}
