























import Component from 'vue-class-component';
import Vue from 'vue';

const THRESHOLD_WARNING_IN_PERCENT = 15;
const THRESHOLD_DANGER_IN_PERCENT = 5;

@Component({
  name: 'RemainingVisitsHint'
})
export default class RemainingVisitsHint extends Vue {
  /**
   * Return true when number of remaining visits is below threshold
   */
  get Visible () {
    return this.RemainingVisitsInPercent <= THRESHOLD_WARNING_IN_PERCENT && this.RemainingVisits !== null
      && this.RemainingVisits !== undefined;
  }

  get MaxVisits () {
    return this.$stripe.packageScope.maxGuestAmount;
  }

  get RemainingVisits () {
    return this.$auth.venueOwner?.remainingVisits;
  }

  get RemainingVisitsInPercent () {
    if (!this.RemainingVisits) {
      return 0;
    }
    return this.ensureBounds((100 / this.MaxVisits) * this.RemainingVisits);
  }

  get WarningType () {
    if (this.$stripe.isSubscribedCustomProduct) {
      return 'is-info';
    }
    return this.RemainingVisitsInPercent <= THRESHOLD_DANGER_IN_PERCENT
      ? 'is-danger'
      : 'is-warning';
  }

  // Ensure a lower and upper limit on a number
  private ensureBounds (val: number, lower = 0, upper = 100) {
    return Math.min(Math.max(lower, val), upper);
  }
}
