/* eslint-disable import/no-duplicates */
import 'reflect-metadata';
import Vue from 'vue';
import App from './App.vue';
import router, { RouteNames } from './router';
import { store } from '@/store';
import '@/assets/styles.scss';
import User from 'firebase';
import '@mdi/font/css/materialdesignicons.css';
import { AuthModule } from './store/modules/auth';
import { AuthPlugin } from './plugins/auth-plugin';
import { getModule } from 'vuex-module-decorators';
import { firebaseWrapper } from './firebase-wrapper';
import FormDivider from '@/components/form-divider.vue';
import { StripePlugin } from './plugins/stripe-plugin';
import * as Sentry from '@sentry/vue';
import VueI18n from 'vue-i18n';

import Menu from 'buefy';
import MenuList from 'buefy';
import MenuItem from 'buefy';
import Message from 'buefy';
import Sidebar from 'buefy';
import Field from 'buefy';
import Input from 'buefy';
import Select from 'buefy';
import Button from 'buefy';
import Loading from 'buefy';
import Skeleton from 'buefy';
import Tabs from 'buefy';
import TabItem from 'buefy';
import Upload from 'buefy';
import Icon from 'buefy';
import Steps from 'buefy';
import StepItem from 'buefy';
import CheckBox from 'buefy';
import TableColumn from 'buefy';
import Table from 'buefy';
import Toast from 'buefy';
import { Integrations } from '@sentry/tracing';
import { VueEnvHelper } from './helpers/vue-envvar-helper';
import { VenuesPlugin } from './plugins/venues-plugin';
import { InteractionPlugin } from './plugins/interaction-plugin';
import * as deTranslation from './translation/de';
import * as enTranslation from './translation/en';

class AppBootstrap {
  constructor () {
    this.loadVueApp();
  }

  private loadVueApp () {
    // Plugins
    Vue.use(AuthPlugin);
    Vue.use(StripePlugin);
    Vue.use(VenuesPlugin);
    Vue.use(InteractionPlugin);
    Vue.use(Menu);
    Vue.use(MenuList);
    Vue.use(MenuItem);
    Vue.use(Message);
    Vue.use(Sidebar);
    Vue.use(Field);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Button);
    Vue.use(Loading);
    Vue.use(Skeleton);
    Vue.use(Tabs);
    Vue.use(TabItem);
    Vue.use(Upload);
    Vue.use(Icon);
    Vue.use(Steps);
    Vue.use(StepItem);
    Vue.use(CheckBox);
    Vue.use(TableColumn);
    Vue.use(Table);
    Vue.use(Toast);
    Vue.use(VueI18n);
    // Components
    Vue.component('FormDivider', FormDivider);

    Vue.config.productionTip = false;

    const envHelper = new VueEnvHelper();
    const sentryDsn = envHelper.get('SENTRY_DSN');
    if (sentryDsn) {
      Sentry.init({
        Vue,
        dsn: envHelper.get('SENTRY_DSN'),
        autoSessionTracking: true,
        environment: (envHelper.get('ENVIRONMENT') || process.env.NODE_ENV).toLowerCase(),
        integrations: [
          new Integrations.BrowserTracing()
        ],
        release: envHelper.get('GIT_SHA'),
        tracesSampleRate: 1.0,
        logErrors: true
      });
    }

    const authStore = getModule(AuthModule, store);

    const i18n = new VueI18n({
      locale: 'de',
      messages: {
        de: deTranslation.default,
        en: enTranslation.default
      }
    });

    (async () => {
      await firebaseWrapper.initialize();
      firebaseWrapper.analytics();

      firebaseWrapper.auth.onAuthStateChanged((user: User.User) => {
        authStore.initApp().then(() => {
          Sentry.setUser({ id: authStore.user?.uid });
          // at this time router.currentRoute is not init
          if (window.location.pathname?.toLowerCase() === '/password-reset') {
            router.push({ name: RouteNames.PasswordReset });
          } else if (!user) {
            if (window.location.pathname.toLowerCase() === '/register') {
              router.push({ name: RouteNames.Registration });
            } else {
              // when user logged out, the route set everytime to login (BEFORE Vue init/mount)
              router.push({
                name: RouteNames.Login,
                params: {
                  redirectUrl: (window.location.pathname !== '/login')
                    ? window.location.pathname
                    : null
                }
              });
            }
          }
          new Vue({
            i18n,
            router,
            render: h => h(App)
          }).$mount('#app');
          // have to check it here, because after this function the "router" is not really initialized yet
          // when user is logged in and try login or register in Url => the user redirect to Dashboard
          if (user && (router.currentRoute.name?.toLowerCase() === 'login'
            || router.currentRoute.name?.toLowerCase() === 'registration')) {
            router.push({ name: RouteNames.Dashboard });
          }
          // when user is not logged in and try call direct register => the register page will load
          // all other Urls redirect to login
          if (!user && router.currentRoute.name?.toLowerCase() !== 'login') {
            if (router.currentRoute.name?.toLowerCase() === 'registration'
            || router.currentRoute.name?.toLowerCase() === 'passwordreset') {
              return;
            }
            router.push({ name: RouteNames.Login });
          }
        }).catch((e) => {
          console.log(e);
          alert(e);
        });
      });
    })();
  }
}

// eslint-disable-next-line no-new
new AppBootstrap();
